import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

function DeliveryOptions({item, store}) {
    function handleInputChange(e) {

    }

    return (<>
      <FormControl sx={{ mt:1, minWidth: 240 }} size="small">
          <InputLabel id="delivery-label">Delivery</InputLabel>
          <Select
            disabled={true}
            id="select-delivery"
            labelId="delivery-label"
            name="delivery"
            label="Delivery options"
            defaultValue={"local-pickup"}
            onChange={handleInputChange}>
            <MenuItem key={"local-pickup"} value={"local-pickup"}>{store.defaultDelivery}</MenuItem>
          </Select>
          <FormHelperText>1 option available</FormHelperText>
        </FormControl>
        {item.shippingFee ? <Typography sx={{ml:1, fontSize: 14 }} variant={"caption"}>+ ${item.shippingFee.toFixed(2)} shipping</Typography> : ''}
    </>);
}

export default DeliveryOptions;