import React, { useEffect } from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../RealmApp";
import { Button, Card, CircularProgress, Divider, Typography } from "@mui/material";

function AuthRedirect() {
  const realmApp = useRealmApp();
  const { currentUser } = realmApp;
  const [loading, setLoading] = React.useState(true);

  function getLoginRedirectPath() {
    return sessionStorage.getItem("redirect_pathname")
  }

  const REDIRECT_HASH_TO_RESULT = {
    access_token: "accessToken",
    state: "state"
  };

  function decodeQueryString(str) {
    const cleanStr = str[0] === "?" ? str.substr(1) : str;
    return Object.fromEntries(
      cleanStr
        .split("&")
        .filter((s) => s.length > 0)
        .map((kvp) => kvp.split("="))
        .map(([k, v]) => [k, decodeURIComponent(v)]),
    );
  }

  function parseRedirectLocation(queryString) {
    const params = decodeQueryString(queryString);
    const result = {};
    for (const [p, r] of Object.entries(REDIRECT_HASH_TO_RESULT)) {
      const value = params[p];
      if (value) {
        result[r] = value;
      }
    }
    return result;
  }

  function redirectBacktoPath() {
    if (getLoginRedirectPath()) {
      setTimeout(() => {
        // @ts-ignore
        window.location = getLoginRedirectPath();
      }, 200);
    }
  }

  const isFacebookInAppBrowser = () => /FB_IAB/.test(navigator.userAgent) ||
    /FBAN/.test(navigator.userAgent) ||
    /FBAV/.test(navigator.userAgent);

  useEffect(() => {
    // Realm.handleAuthRedirect() -> https://github.com/realm/realm-js/blob/main/packages/realm-web/src/OAuth2Helper.ts#L110
    const qs = window.location.hash.substring(1);
    const { accessToken, state } = parseRedirectLocation(qs);
    console.log({ accessToken }, { state });
    if (accessToken) {
      const credentials = Realm.Credentials.facebook(accessToken);
      realmApp.logIn(credentials).then((user) => {
        console.log('Logged in via AuthRedirect');
        redirectBacktoPath();
      });
    }
    setTimeout(() => {
      setLoading(false)
    }, 4000);
  });

  return (
    <div>
      {currentUser ? <Typography sx={{ p: 4, m: 4, textAlign: 'center' }}>You are being logged in! <CircularProgress /> </Typography> :
        <>
          <Card sx={{ p: 3, m: 2, textAlign: 'center' }}>
            <Typography sx={{ mt: 2 }}>
              {loading ? <CircularProgress /> :
                <>
                  Sorry, something went wrong. Click 'Continue' to retry
                </>
              }
            </Typography>
            <Divider />
            <Button sx={{ p: 2, m: 2 }} variant="outlined"
              onClick={(e) => {
                // Realm.handleAuthRedirect() -> https://github.com/realm/realm-js/blob/main/packages/realm-web/src/OAuth2Helper.ts#L110
                const qs = window.location.hash.substring(1);
                const { accessToken, state } = parseRedirectLocation(qs)
                console.log(accessToken, state)
                const credentials = Realm.Credentials.facebook(accessToken);
                realmApp.logIn(credentials).then((user) => {
                  // console.log('Logged in via AuthRedirect');
                  redirectBacktoPath();
                });
              }}
            > Continue </Button>

            {isFacebookInAppBrowser() ? <Typography variant="h6">Facebook browser not supported. Click the "Open in System Browser" option. </Typography> : ""}

          </Card>
        </>}
    </div>
  );
}

export default AuthRedirect;