import { BSON } from "realm-web";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";

const { dataSourceName } = Config();

export function useListing() {
  const realmApp = useRealmApp();

  const listingCollection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "listings",
  });

  /**
   * @param {Listing} draftItem 
   */
  const insertListing = async (draftItem) => {
    if (draftItem.imageUrl) {
      draftItem._partition = realmApp.currentUser.id;
      try {
        await listingCollection.insertOne(draftItem);
      } catch (err) {
        if (err.error.match(/^Duplicate key error/)) {
          console.warn(`The following error means that we tried to insert same _id multiple times. `);
        }
        console.error(err);
      }
    }
  };

  const updateListingPart = async (id, doc) => {
    try {
      const ID = new BSON.ObjectID(id);
      console.log('updateListing:pre', {doc}, id)
      const res = await listingCollection.updateOne(
        {_id: ID},
        { $set: doc }
      );
      console.log('updateListing:', res);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(`Duplicate key error`);
      }
      console.error(err);
    }
  };

  const updateListing = async (listing) => {
    console.log('updateListing',{listing})
    const BsonId = new BSON.ObjectID(listing._id);
    try {
      await listingCollection.updateOne(
        {_id: BsonId},
        { $set: listing }
      );
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(`Tried to insert a doc multiple times`);
      }
      console.error(err);
    }
  };

  return {
    insertListing,
    updateListing,
    updateListingPart,
  };
}
