/// <reference path="../../../typedefs.js" />
import React from "react";
import { Button, Container, LinearProgress, Typography } from "@mui/material";
import { useShowLoader } from "../../../hooks/util-hooks";
import { SingleImageList } from "../../lists/SingleImageList";
import { Link } from "react-router-dom";
import { SearchBar } from "../../controls/SearchBar";
import Welcome from "./Welcome";
import { useAxiosEndpoint } from "../../../hooks/useAxiosEndpoint";
import { ExpandMore, NewReleases } from "@mui/icons-material";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { ErrorPage } from "../ErrorPage";
import { SearchDialog } from "../../controls/SearchDialog";

/**
 * @param {Category} cat 
 * @returns {Category}
 */
function mapCats(cat) {
  if (cat.home) return cat;
}

/**
 * @param {Category} cat 
 * @returns {Category}
 */
function otherCats(cat) {
  if (!cat.home) return cat;
}

/**
 * @param {Listing} i 
 * @param {number} idx 
 * @param {Category[]} coverCategories 
 * @returns {boolean}
 */
function itemsFilter(i, idx, coverCategories) {
  if (i.category === coverCategories[idx]?.slug) {
    return true;
  } else if (i.category2 === coverCategories[idx]?.slug) {
    return false; // option to include cat2 on homepage
  }
  return false;
}

/**
 * Homepage Component
 * @param {object} Props
 * @param {Store} Props.store
 * @param {any} Props.setShowSearch
 * @param {any} Props.showSearch
 * @returns {JSX.Element}
 */
export function HomePage({ store, setShowSearch, showSearch }) {
  const coverCategories = store.categories.map(mapCats).filter(a => a);
  const otherCategories = store.categories.map(otherCats).filter(a => a);
  const cats = coverCategories.map(c => c.slug).join(',');
  const queryParams = { limit: 60, storeId: store._id, category: cats };
  const { data, loading, error } = useAxiosEndpoint('listings', { queryParams, useCache: true });
  const { data: newitems, loading: loadingNew } = useAxiosEndpoint('listings', { queryParams: { limit: 8, storeId: store._id } });
  const showLoader = useShowLoader(loading, 400);

  useDocumentTitle(`${store.name} Home`);

  if (error) {
    return <ErrorPage error={error} />
  }

  let coverItems = {}
  if (!loading && data.documents) {
    const items = data.documents;
    coverItems = {
      cat0: items.filter(i => itemsFilter(i, 0, coverCategories)),
      cat1: items.filter(i => itemsFilter(i, 1, coverCategories)),
      cat2: items.filter(i => itemsFilter(i, 2, coverCategories)),
      cat3: items.filter(i => itemsFilter(i, 3, coverCategories)),
      cat4: items.filter(i => itemsFilter(i, 4, coverCategories)),
    };
  }

  // wont work!
  // if(searchParams.get('q')) {
  //   setShowSearch(true);
  // }

  return (
    <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div>
          <Welcome store={store} />
          <SearchBar setShowSearch={setShowSearch} />
          <Button
            variant={"contained"}
            startIcon={<NewReleases />}
            sx={{ borderRadius: 6, mt: 1 }} component={Link} to={`/category/new`} >New</Button>
          {/* <Divider variant="inset" /> */}
          {!loadingNew && <SingleImageList items={newitems.documents.slice(0, 7)} />}
          {coverCategories.map((cat, idx) => (
            <span key={idx}>
              <Button
                sx={{ borderRadius: 6, mt: 1 }}
                startIcon={<ExpandMore />}
                variant="contained"
                component={Link}
                to={`/category/${cat.slug}`}
              >{cat.name}</Button>
              {/* <Divider variant="inset" /> */}
              <SingleImageList items={coverItems[`cat${idx}`]} />
            </span>
          ))}
          <Typography gutterBottom variant="subtitle2" >More categories</Typography>
          {otherCategories.map((cat, idx) => (
            <span key={idx}>
              <Button
                size={"small"}
                variant="contained"
                sx={{ borderRadius: 6, mr: 0.5, mb: 0.4 }}
                component={Link}
                to={`/category/${cat.slug}`}>{cat.name}</Button>
            </span>
          ))}
           <span>
              <Button
                size={"small"}
                variant="contained"
                sx={{ borderRadius: 6, mr: 0.5, mb: 0.4 }}
                component={Link}
                to={`/sale`}>On Sale</Button>
            </span>
        </div>)}
        <SearchDialog store={store} open={showSearch} setShowSearch={setShowSearch} />
    </Container>
  );
}
