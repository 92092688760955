import React from "react";
import Uploady, { UPLOADER_EVENTS } from "@rpldy/uploady";
import { CircularProgress } from "@mui/material";
import { ProfileUploadButton } from "./ProfileUploadButton";
import { getUploadURL } from "../../../shared/getEndpoint";
// const CLOUD_NAME = "dg6z5xnnv";
// const UPLOAD_PRESET = "unsigned_ninja";

/**
 * 
 * @param {object} props
 * @param {Function} props.upsertUser
 * @param {any} props.currentUser
 * @param {string} [props.buttonTitle]
 * @param {any} [props.children]
 * @returns 
 */
function ProfileImageUpload({ buttonTitle = "", upsertUser, children, currentUser }) {
    const [loading, setLoading] = React.useState(false);
    const [uploaded, setUploaded] = React.useState(false);

    const listeners = React.useMemo(() => ({
        [UPLOADER_EVENTS.BATCH_ADD]: (item) => {
            console.log(`BATCH_ADD`, item);
        },
        [UPLOADER_EVENTS.REQUEST_PRE_SEND]: async (context) => {
            const data = await getUploadURL({token: currentUser.accessToken});
            context.options.destination = { url: data.result.uploadURL };
            console.log('REQUEST_PRE_SEND', context);
            return context;
        },
        [UPLOADER_EVENTS.ITEM_START]: (item) => {
            setLoading(true);
            console.log(`ITEM_START - ${item.id} : ${item.file.name}`);
        },
        [UPLOADER_EVENTS.ITEM_FINISH]: (item) => {
            setLoading(false);
            setUploaded(true);
            console.log(`ITEM_FINISH - ${item.id} : ${item.file.name}`);
        },
    }), [currentUser]);

    const resFormatter = React.useCallback((res, status, headers) => {
        const response = JSON.parse(res)
        console.log("# img URL:", response.result, status);
        upsertUser({ imageUrl: `https://imagedelivery.net/JsOwOQEQsyMK6pDicrj7_w/${response.result.id}` });
        return `${status} - OK!`;
    }, [upsertUser]);

    return (
        <Uploady
            listeners={listeners}
            multiple={false}
            grouped
            maxGroupSize={2}
            method="POST"
            formatServerResponse={resFormatter}
            >
            <ProfileUploadButton buttonTitle={buttonTitle} uploaded={uploaded}>
                {children}
            </ProfileUploadButton>
            {loading && <CircularProgress />}
        </Uploady>);
}

export default ProfileImageUpload;