import React from "react";
import Debug from "debug";
import { Autocomplete, Container, Divider, ImageList, ImageListItem, ImageListItemBar, InputAdornment, LinearProgress, TextField } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import ItemImage from "../controls/ItemImage";
import { Search } from "@mui/icons-material";
import { useDebounce } from "../../hooks/useDebounce";
import { SearchItems } from "../../shared/getEndpoint";
import { searchTerms, trackEvent } from "../../shared/helpers";

const debug = Debug("SearchPage");
const CACHE = {}


export function SearchPage({store}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchItems, setSearchItems] = React.useState([]);
    const [loadingSearch, setSearchLoading] = React.useState(false);
    const [searchActive, setSearchActive] = React.useState(false);
    const [value, setValue] = React.useState(searchParams.get('q'));
    const debouncedValue = useDebounce(value, 100); // was 300
  

    const handleInputChange = async (e, input) => {
        const len = input.length
        if (input === "") {
          debug('clear input', input);
          setSearchLoading(false);
          setSearchActive(false);
          setSearchItems([]);
        }
        if (len > 2) { // && !(len % 2)
          setSearchActive(true);
          setSearchLoading(true);
          debug('inputChange:', input)
          setValue(input);
          setSearchParams({ q: input })
        }
      };

      const search = React.useCallback(async () => {
        if (!debouncedValue) {
          return;
        }
        setSearchLoading(true);
        let data;
        const cacheKey = `search_${debouncedValue}_${new Date().getHours()}`; // 1hr TTL
        if (CACHE[cacheKey]) {
          debug('CACHE hit', cacheKey);
          data = CACHE[cacheKey];
        } else {
          data = await SearchItems(debouncedValue, store._id);
          debug(`got /search?q=${debouncedValue}`, { data });
          if (debouncedValue?.length > 3) {
            trackEvent({ action: 'search', params: { search_term: debouncedValue } });
          }
          CACHE[cacheKey] = data;
        }
        setSearchLoading(false);
        setSearchItems(data);
      }, [debouncedValue, store]);
    
      React.useEffect(() => {
        search();
      }, [debouncedValue, search]);    

    return (
        <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
              <Autocomplete
            freeSolo
            id="free-solo-search"
            value={value}
            disableClearable
            onInputChange={handleInputChange}
            options={searchTerms.split(',').map((i) => i)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search..."
                InputProps={{
                  sx: { borderRadius: 6 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
            <Divider />
            <ImageList>
                {searchItems.map((item) => (
                    <ImageListItem key={item._id}>
                        <Link to={"/items/" + item._id}>
                            <ItemImage url={item.imageUrl} />
                            <ImageListItemBar
                                title={item.title}
                                subtitle={<span>${item.price} size:{item.size} </span>}
                            />
                        </Link>
                    </ImageListItem>
                ))}
            </ImageList>
            {!loadingSearch && searchActive && (searchItems?.length === 0) && <div>&nbsp; &nbsp; No search results</div>}
            {loadingSearch && <LinearProgress sx={{ mt: 1 }} />}
        </Container>
    )
}