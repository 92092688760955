import React, { useCallback, useMemo } from "react";
import {
  CircularProgress,
  TextField,
  Button,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { UploadButton } from "../../controls/UploadButton";
import { Clear as ClearIcon } from "@mui/icons-material";
import Uploady, { UPLOADER_EVENTS } from "@rpldy/uploady";
import { useStoreSettings } from "../../../hooks/useStoreSettings";
import { itemConditions, itemImageURL } from "../../../shared/helpers";
import { getUploadURL } from "../../../shared/getEndpoint";

const styles = {
  root: { maxWidth: 520 },
  placeholder: { height: 200 },
  media: { height: 0, paddingTop: '120%' }
};

export function DraftItemCard({ item, listingActions, draftActions, consignors, categories, sizes, currentUser }) {
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [formComplete, setFormComplete] = React.useState(false);
  const [imgCount, setImgCount] = React.useState(0);
  const { store } = useStoreSettings();

  const resFormatter = useCallback((res, status, headers) => {
    const response = JSON.parse(res);
    //console.log("### URL:", response.secure_url, status);
    //draftActions.setDraftImageUrl(item, response.secure_url);
    console.log("# img URL:", response.result, status);
    draftActions.setDraftImageUrl(item, `https://imagedelivery.net/JsOwOQEQsyMK6pDicrj7_w/${response.result.id}`);
    return `${status} - OK!`;
  }, [draftActions, item]);

  const handleTextFieldChange = (e) => {
    console.log('target:', e.target, { item });
    draftActions.setDraftTextField(item, e.target);
  };
  const handleNumberFieldChange = (e) => {
    draftActions.setDraftNumberField(item, e.target);
  };

  const listeners = useMemo(() => ({
    // [UPLOADER_EVENTS.BATCH_START]: (batch) => { console.log(`Batch Start - ${batch.id} - item count = ${batch.items.length}`);},
    // [UPLOADER_EVENTS.BATCH_FINISH]: (batch) => { console.log(`Batch Finish - ${batch.id} - item count = ${batch.items.length}`); },
    [UPLOADER_EVENTS.REQUEST_PRE_SEND]: async (context) => {
      const data = await getUploadURL({token: currentUser.accessToken});
      context.options.destination = { url: data.result.uploadURL };
      console.log('REQUEST_PRE_SEND', context);
      return context;
  },
    [UPLOADER_EVENTS.ITEM_START]: (item) => {
      setLoading(true);
      console.log(`ITEM_START - ${item.id} : ${item.file.name}`);
    },
    [UPLOADER_EVENTS.ITEM_FINISH]: (item) => {
      setLoading(false);
      setUploaded(true);
      setImgCount((curr) => curr + 1)
      console.log(`ITEM_FINISH - ${item.id} : ${item.file.name}`);
    },
  }), [currentUser]);
  return (
    <Card style={styles.root}>
      {uploaded ? <CardMedia style={styles.media} title="Product"
        image={itemImageURL(item.imageUrl, { large: true })}
      /> :
        <CardMedia style={styles.placeholder} title="Draft Product"
          image={'https://via.placeholder.com/800x600.png/eee/000?text=Upload+Product+Image'}
        />}
      {item.imageUrl2 &&
        <CardMedia style={styles.media} title="Product"
          image={itemImageURL(item.imageUrl2, { large: true })}
        />
      }
      {item.imageUrl3 &&
        <CardMedia style={styles.media} title="Product"
          image={itemImageURL(item.imageUrl3, { large: true })}
        />
      }
      {item.imageUrl4 &&
        <CardMedia style={styles.media} title="Product"
          image={itemImageURL(item.imageUrl4, { large: true })}
        />
      }
      {item.imageUrl5 &&
        <CardMedia style={styles.media} title="Product"
          image={itemImageURL(item.imageUrl5, { large: true })}
        />
      }
      {item.imageUrl6 &&
        <CardMedia style={styles.media} title="Product"
          image={itemImageURL(item.imageUrl6, { large: true })}
        />
      }
      <CardContent>
        <Uploady
          listeners={listeners}
          multiple={false}
          grouped
          maxGroupSize={2}
          method="POST"
          formatServerResponse={resFormatter}
        >
          <UploadButton imgCount={imgCount} />
          {loading && <CircularProgress />}
        </Uploady>
        <span className="auth-form">
          <TextField
            id="input-title"
            name="title"
            label="Title *"
            variant="outlined"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e.target.value?.length > 2) {
                setFormComplete(true)
              } else {
                setFormComplete(false)
              }
              draftActions.setDraftTextField(item, e.target);
            }}
          />
          <TextField
            id="input-description"
            multiline={true}
            name="description"
            label="Description"
            variant="outlined"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e.target.value?.length > 2) {
                setFormComplete(true)
              } else {
                setFormComplete(false)
              }
              draftActions.setDraftTextField(item, e.target);
            }}
          />
          <div>
            <TextField
              id="input-price"
              name="price"
              label="Price *"
              variant="outlined"
              sx={{ maxWidth: 160 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={handleNumberFieldChange} />
            <TextField
              id="input-retailprice"
              name="retailPrice"
              label="Retail Price"
              variant="outlined"
              sx={{ ml: 2, maxWidth: 160 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={handleNumberFieldChange} />
          </div>
          <FormControl>
            <InputLabel id="size-label">Size</InputLabel>
            <Select
              id="select-size"
              labelId="size-label"
              name="size"
              label="Size"
              defaultValue=""
              onChange={handleTextFieldChange}>
              {sizes.map((item) => (
                <MenuItem key={String(item.key)} value={item.key}>{item.key}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={styles.formControl}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="select-category"
              label="Category"
              name="category"
              defaultValue=""
              onChange={handleTextFieldChange}>
              {categories.map((cat) => (
                <MenuItem key={String(cat.slug)} value={cat.slug}>{cat.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={styles.formControl}>
            <InputLabel id="category2-label">2nd Category (optional)</InputLabel>
            <Select
              labelId="category2-label"
              id="select-category2"
              label="2nd Category"
              name="category2"
              defaultValue=""
              onChange={handleTextFieldChange}>
              {categories.map((cat) => (
                <MenuItem key={String(cat.slug)} value={cat.slug}>{cat.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {itemConditions?.length > 0 &&
            <FormControl>
              <InputLabel id="condition-label">Condition</InputLabel>
              <Select
                id="select-condition"
                labelId="condition-label"
                name="condition"
                defaultValue=""
                label="Condition"
                onChange={handleTextFieldChange}>
                <MenuItem value="">-- n/a --</MenuItem>
                {itemConditions.map((condition) => (
                  <MenuItem key={String(condition.code)} value={condition.code}>{condition.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          {store.enableInventory &&
            <TextField
              id="input-count"
              name="inventoryCount"
              label="Inventory Count"
              defaultValue={1}
              variant="outlined"
              style={{ width: "100%" }}
              onChange={handleNumberFieldChange} />
          }
          {store.isConsignment &&
            <FormControl>
              <InputLabel id="consignor-label">Consignor</InputLabel>
              <Select
                id="select-consignor"
                labelId="consignor-label"
                name="consignor"
                defaultValue=""
                label="Consignor"
                onChange={handleTextFieldChange}>
                {consignors.map((i) => (
                  <MenuItem key={String(i._id)} value={i.venmo}>{i.name} ({i.venmo})</MenuItem>
                ))}
              </Select>
            </FormControl>}
          {store.defaultShippingFee &&
            <TextField
              id="input-count"
              name="shippingFee"
              label="Shipping Fee"
              defaultValue={store.defaultShippingFee}
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={handleNumberFieldChange} />
          }
        </span>
      </CardContent>
      <CardActions>
        {loading && <CircularProgress />}
        {(uploaded && formComplete) &&
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            onClick={async () => {
              item.storeId = store._id;
              console.log({ item });
              await listingActions.insertListing(item);
              draftActions.deleteDraftItem(item);
            }}> Save
          </Button>
        }
        <Button
          variant="outlined"
          size="medium"
          onClick={() => {
            draftActions.deleteDraftItem(item);
          }}
        > Cancel
          <ClearIcon />
        </Button>
      </CardActions>
    </Card>
  );
}
