import Debug from "debug";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material";
// import { pink } from '@mui/material/colors'; // color example

import { useStoreSettings } from "../hooks/useStoreSettings";
import { useRealmApp } from "./RealmApp";
import React from "react";

const debug = Debug("Theme")

export function ThemeProvider({ children }) {
  const { currentUser } = useRealmApp();
  const { store, loading } = useStoreSettings(currentUser); // use true to force refresh

  const defaultThemeConfig = {
    palette: {
      // mode:'light',
      primary: { main: '#000000' },
      secondary: { main: '#EEEEEE' },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      }
    }
  };
  const theme1 = createTheme(defaultThemeConfig);
  let theme = theme1;

  let themeConfig = defaultThemeConfig;
  if (!loading && store.theme) {
    debug('store.theme:', store.theme);
    themeConfig.palette.primary.main = store.theme.split(',')[0];
    themeConfig.palette.secondary.main = store.theme.split(',')[1];
  try {
    theme = createTheme(themeConfig);
  } catch (ex) {
    console.error(ex);
  }
}
// console.log({theme});

return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
