import React from "react";
import { Avatar, Box, Container, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Rating } from "@mui/material";
import { Link as RLink } from "react-router-dom";
import { useSellers } from "../../hooks/useSellers";
import { ChevronRight } from "@mui/icons-material";
import { avatarImageURL } from "../../shared/helpers";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";


export function SellersPage({ store }) {
    const { sellers, loading } = useSellers(store);

    useDocumentTitle('Sellers');

    return (
        <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
            {!loading ? <div>
                <h3>{store.name} Sellers</h3>
                <Box sx={{ m: 2 }} >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {sellers.map((doc, idx) => (
                            <span key={idx}>
                                <ListItem sx={{ border: '1px solid #ccc' }} divider={true} component={RLink} to={`/seller/${doc.id}`}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="comments">
                                            <ChevronRight />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar src={avatarImageURL(doc.imageUrl)}>
                                            {doc.fullname[0][0]}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<>Seller: {doc.fullname}</>}
                                        secondary={
                                            <Rating name="read-only" value={4.5} readOnly precision={0.5} />
                                        } />
                                </ListItem>
                            </span>
                        ))}
                    </List>
                </Box>

            </div> : <LinearProgress />}
        </Container>
    );
}
