import React from "react";
import Debug from "debug";
import { Dialog, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, ImageListItemBar, InputAdornment, LinearProgress, TextField, useMediaQuery, useTheme } from "@mui/material";
import { CloseRounded, Search } from "@mui/icons-material";
import Autocomplete from '@mui/material/Autocomplete';
import { Link, useSearchParams } from "react-router-dom";
import { SearchItems } from "../../shared/getEndpoint";
import { useDebounce } from "../../hooks/useDebounce";
import { searchTerms, trackEvent } from "../../shared/helpers";
import ItemImage from "./ItemImage";

const debug = Debug("SearchBar");
const CACHE = {}

/**
 * SearchDialog Component
 * @param {object} Props
 * @param {Store} Props.store
 * @param {any} Props.setShowSearch
 * @param {any} Props.open
 * @returns {JSX.Element}
 */
export function SearchDialog({ store, setShowSearch, open }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchItems, setSearchItems] = React.useState([]);
  const [loadingSearch, setSearchLoading] = React.useState(false);
  const [searchActive, setSearchActive] = React.useState(false);
  const [value, setValue] = React.useState(searchParams.get('q'));
  const debouncedValue = useDebounce(value, 100); // was 300

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogTitleStyle = { minWidth: 320 }
  if (!fullScreen) {
    dialogTitleStyle.minWidth = 520
  }

  function handleClose(value) {
    setShowSearch(false);
  }

  const search = React.useCallback(async () => {
    if (!debouncedValue) {
      return;
    }
    setSearchLoading(true);
    let data;
    const cacheKey = `search_${debouncedValue}_${new Date().getHours()}`; // 1hr TTL
    if (CACHE[cacheKey]) {
      debug('CACHE hit', cacheKey);
      data = CACHE[cacheKey];
    } else {
      data = await SearchItems(debouncedValue, store._id);
      debug(`got /search?q=${debouncedValue}`, { data });
      if (debouncedValue?.length > 3) {
        trackEvent({ action: 'search', params: { search_term: debouncedValue } });
      }
      CACHE[cacheKey] = data;
    }
    setSearchLoading(false);
    setSearchItems(data);
  }, [debouncedValue, store]);

  React.useEffect(() => {
    search();
  }, [debouncedValue, search]);

  const handleInputChange = async (e, input) => {
    const len = input.length
    if (input === "") {
      debug('clear input', input);
      setSearchLoading(false);
      setSearchActive(false);
      setSearchItems([]);
    }
    if (len > 2) { // && !(len % 2)
      setSearchActive(true);
      setSearchLoading(true);
      debug('inputChange:', input)
      setValue(input);
      setSearchParams({ q: input })
    }
  };

  return (
    <>
      <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
        <DialogTitle sx={dialogTitleStyle}>
          <IconButton style={{ float: 'right' }} onClick={() => setShowSearch(false)}>
            <CloseRounded />
          </IconButton>
          <Autocomplete
            freeSolo
            id="free-solo-search"
            value={value}
            disableClearable
            onInputChange={handleInputChange}
            options={searchTerms.split(',').map((i) => i)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search..."
                InputProps={{
                  sx: { borderRadius: 6 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </DialogTitle>
        <DialogContent>
          <ImageList>
            {searchItems.map((item) => (
              <ImageListItem key={item._id}>
                <Link to={"/items/" + item._id}>
                  <ItemImage url={item.imageUrl} />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={<span>${item.price} size:{item.size} </span>}
                  />
                </Link>
              </ImageListItem>
            ))}
          </ImageList>
          {!loadingSearch && searchActive && (searchItems?.length === 0) && <div>&nbsp; &nbsp; No search results</div>}
          {loadingSearch && <LinearProgress sx={{ mt: 1 }} />}
        </DialogContent>
      </Dialog>
    </>

  )
}