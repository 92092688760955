import React, { useCallback } from "react";
import { useUploady } from "@rpldy/uploady";
import { Button } from "@mui/material";
import PhotoCamera from '@mui/icons-material/PhotoCamera';

export function UploadButton({ imgCount }) {
  const MAXIMGS = 6
  const { showFileUpload } = useUploady();

  const onButtonClick = useCallback(async (e) => {
    showFileUpload();
  }, [showFileUpload]);

  return <Button
    sx={{ mb: 2 }}
    variant="outlined"
    disabled={imgCount >= MAXIMGS}
    onClick={onButtonClick}>
    <PhotoCamera />
    Upload {(imgCount > 0 && imgCount < MAXIMGS) && `Image ${imgCount + 1}`}
  </Button>;
}
