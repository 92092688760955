import React from "react";
import { Button, Container, Divider, LinearProgress } from "@mui/material";
import { useUsers } from "../../../hooks/useUsers";
import ProfileForm from "./ProfileForm";
import { SellerSettings } from "./SellerSettings";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Link } from "react-router-dom";
import { Settings } from "@mui/icons-material";


export function ProfilePage({ store, currentUser }) {
  const { upsertUser, user, loading } = useUsers();

  useDocumentTitle('Profile');

  return (
    <Container maxWidth="sm" disableGutters={true} className="main-container">
      {loading ? <LinearProgress /> :
        <>
          <ProfileForm
            user={user}
            store={store}
            currentUser={currentUser}
            upsertUser={upsertUser}
          />
          <SellerSettings
            upsertUser={upsertUser}
            store={store}
            user={user}
            currentUser={currentUser} />

          {currentUser.customData?.isAdmin ?
            <>
              <Divider textAlign="left" sx={{ my: 2 }}>ADMIN</Divider>
              <Button component={Link} size="small" variant={"contained"} startIcon={<Settings />} to={`/admin/${store._id}/categories`}>Edit Categories</Button>
            </>
            : ""}
        </>}
    </Container>)
}
