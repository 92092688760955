import React from "react";

export function useTimeout(fn, ms) {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      fn();
    }, ms);
    return () => clearTimeout(timeout);
  }, [fn, ms]);
}

export function timeAgo(created_ts) {
  const today = new Date();
  const createdOn = new Date(created_ts);
  const msInDay = 24 * 60 * 60 * 1000;
  const msInMin = 60 * 1000;
  const msInHr = 60* 60 * 1000;
  const diff = (+today - +createdOn)
  const days = diff/msInDay
  const mins = diff/msInMin
  const hrs = diff/msInHr
  if (days > 1) {
    return `${days.toFixed(0)}d`
  } else if (hrs > 1) {
    // @ts-ignore
    return `${Math.round(hrs.toFixed(2))}hr(s)`
  } else {
    // @ts-ignore
    return `${Math.round(mins.toFixed(2))}mins`
  }
}

export function useShowLoader(loading, delayMs) {
  const [showLoader, setShowLoader] = React.useState(false);
  useTimeout(() => {
    if (loading) {
      setShowLoader(true);
    }
  }, [delayMs]);
  React.useEffect(() => {
    if (!loading) {
      setShowLoader(false);
    }
  }, [loading]);
  return showLoader;
}

export function addItemToArray(item, items) {
  const _items = Array.from(items.filter(a => a.name));
  _items.unshift(item);
  return _items;
}
