import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import { Link as RLink } from "react-router-dom";
import { timeAgo } from "../../../hooks/util-hooks";
import { Discount } from "@mui/icons-material";

export function ItemsTable({ items, categories, setCategory, category }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogTitleStyle = { minWidth: 320 };
  if (!fullScreen) {
    dialogTitleStyle.minWidth = 520;
  }

  function filterColumn(e) {
    console.log(e.target.innerText, '<<');
  }

  function handleChange(e) {
    const category = e.target.value;
    setCategory(category);
    sessionStorage.setItem('listings:category', category);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 1 }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table sx={{ minWidth: 320 }} size="medium" stickyHeader aria-label="listing table">
            <TableHead>
              <TableRow>
                <TableCell padding={'normal'}>Title</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Listed</TableCell>
                <TableCell onClick={filterColumn}>
                  Category
                  <Select
                    size={"small"}
                    labelId="category-select-label"
                    id="category-select"
                    value={category}
                    label="Category"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>*</MenuItem>
                    {categories.map((cat) => (
                      <MenuItem key={cat.slug} value={cat.slug}>{cat.slug}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((i) => (
                <TableRow hover
                  tabIndex={-1} key={i._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Link component={RLink} to={`/edititem/${i._id}`}>{i.title}</Link>
                  </TableCell>
                  <TableCell>${i.price.toFixed(2)} {i.applyDiscount && <Discount color="info" />} </TableCell>
                  <TableCell>{timeAgo(i.created_ts)} {i.status === 'active' ? '' : '(' + i.status + ')'} </TableCell>
                  <TableCell>{i.category}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
