import React from "react";
import Debug from "debug";
import { useCollection } from "../../hooks/useCollection";
import Config from "../../shared/Config";
import { useRealmApp } from "../../components/RealmApp";
const { dataSourceName } = Config();
const debug = Debug('useChats');

export function useChats() {
  const [chats, setChats] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const realmApp = useRealmApp();

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "chatMessages",
  });

  React.useEffect(() => {
    const groupStage = {
      $group: {
        _id: "$conversationId",
        message: {
          $last: "$$ROOT"
        }
      }
    }

    collection.aggregate([
      //  { "$match" : { "storeId" : store._id } },
      //  { "$match" : { "status" : { "$in": ["active","sold"] }} }
      groupStage,
      { $sort: { "created_ts": -1 } },
      { $limit: 120 },
    ]).then((res) => {
      function sortMsgs(a, b) {
        return b.message.created_ts - a.message.created_ts // reverse chron
      }
      const sorted = res.sort(sortMsgs)
      debug('mongo', { sorted })
      setChats(sorted);
      setLoading(false);
      realmApp.currentUser.callFunction('setUserChatViewed');
    });
  }, [collection, realmApp]);

  return {
    loading,
    chats
  };
}
