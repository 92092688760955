import React from "react";
import { Chip, Container } from "@mui/material";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";


export function CategoriesPage({ store }) {
  const categories = store.categories;
  useDocumentTitle('Categories');

  return (
    <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
        <div>
          <div>All categories</div>
          <Chip sx={{mt:1, color:'blue'}} clickable component={Link} to={`/category/New`} label={"New"} variant="outlined" />
          {categories.map((cat, idx) => (
            <span key={idx}>
              <Chip sx={{ mt:1, color:'blue'}} clickable component={Link} to={`/category/${cat.slug}`} label={cat.name} variant="outlined" />
            </span>
          ))}
        </div>
    </Container>
  );
}
