import { Button, Card, CircularProgress, TextField, Typography } from "@mui/material";
import React from "react";

export function EmailResetForm({ realmApp }) {
  const [error, setError] = React.useState('');
  const resetEmail = React.useRef();
  const [emailSent, setEmailSent] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  return (
    <span>
      {!emailSent ?
        <Card sx={{minWidth:220}} className="auth-card" variant="outlined">
          <Typography sx={{ mb:2 }} variant="h6">Reset password</Typography>
          <TextField
            sx={{mb:2}}
            id="input-reset-email"
            inputRef={resetEmail}
            name="email"
            label="Email Address"
            variant="outlined"
            autoComplete="username"
            error={Boolean(error)}
            helperText={error ?? ""}
          />
          <Button variant="contained" color="primary"
            onClick={async () => {
              try {
                setSending(true);
                // @ts-ignore
                const lowerEmail = resetEmail?.current?.value.toLowerCase();
                const params = { email: lowerEmail, password: window.origin };
                await realmApp.emailPasswordAuth.callResetPasswordFunction(params);
                // old ver -> await realmApp.emailPasswordAuth.sendResetPasswordEmail({ email: lowerEmail });
                setError('')
                setEmailSent(true)
              } catch (ex) {
                console.log('err:', ex.error);
                setError(ex.error);
              }
            }}
          >Send email {sending && <CircularProgress color="secondary" sx={{ml:1}} size={25} />}  </Button>
        </Card> :
        <Card sx={{ p: 2 }}>
          <Typography sx={{ m: 2 }} variant={"h5"}>
            Check your email.
          </Typography>
          <Typography sx={{ textAlign: 'center' }} variant={"body1"}>
            Reset link will expire in 30 mins.
          </Typography>
        </Card>
      }
    </span>
  )
}