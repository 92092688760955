import { Send } from "@mui/icons-material";
import { Card, CardHeader, Divider, Fab, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Debug from "debug";
import { useChatMessages } from "./useChatMessages";
import { useRealmApp } from "../../components/RealmApp";
import ChatMessage from "./ChatMessage";
import { Container } from "@mui/system";

const debug = Debug('Conversation');

export default function Conversation({ chat, isDialog }) {
    // debug("chat", chat);
    const messageInput = React.useRef();
    const realmApp = useRealmApp();
    let { conversationId } = useParams();
    let recipientId = null;

    if (conversationId) {
        
        // debug('has Cid param:', conversationId);
    } else if (chat && chat._id) {
        conversationId = chat._id;
        //debug('got Cid from chat:', conversationId);
        recipientId = chat.message?.recipient;
    }

    // lookup conversation
    const { loading, messages, insertChatMessage } = useChatMessages({ conversationId, recipientId });

    let _chat = {};
    if (chat) {
        _chat = chat;
    }

    if (messages.length) { // prevent getting a new Cid.
        conversationId = messages[0].conversationId;
        if (_chat && !_chat.message) {
            _chat.message = messages[0] // || chat.message; // last message
        } 
        _chat.messages = messages;
        if (!recipientId) {
            recipientId = _chat.message?.recipient
        }
    }

    // filter for Watcher updates
    let _messages = messages;
    if (Array.isArray(_chat.message?.usersInfo)) {
        // console.log('filter ##')
        _messages =  messages.filter((m) => {
            if (!getOtherUser(_chat)) return null;
            return m.users.includes(getOtherUser(_chat).id);
        });
    }

    React.useEffect(() => {
        debug('Conversation: useEffect()', { conversationId, recipientId, chat, messages, currentUser: realmApp.currentUser.id });
    }, [conversationId, recipientId, chat, messages, realmApp.currentUser]);

    function getOtherUser(chat) {
        return chat.message?.usersInfo.find(u => u.id !== realmApp.currentUser.id) || {name: '----'};
    }

    function getSenderInfo(msg) {
        return msg.usersInfo.find(u => u.id === msg.sender);
    }

    function getSide(message) {
        if (message.sender === realmApp.currentUser.id) {
            return "right";
        } else {
            return "left";
        }
    }

    async function handleSend(e) {
        const name = realmApp.currentUser.customData?.fullname || realmApp.currentUser.customData?.email;
        const chatMessage = {
            conversationId,
            users: [getOtherUser(_chat).id, realmApp.currentUser.id],
            usersInfo: [
                { id: realmApp.currentUser.id, name },
                getOtherUser(_chat)
            ],
            sender: realmApp.currentUser.id,
            recipient: getOtherUser(_chat).id,
            // @ts-ignore
            text: messageInput.current.value,
            created_ts: new Date()
        };
        console.log('## chat msg -->', { chatMessage });
        await insertChatMessage(chatMessage);
        // @ts-ignore
        messageInput.current.value = ""
    }

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        // @ts-ignore
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    const containerStyle = isDialog ? null : {position:'fixed'};

    return (
        <Container sx={containerStyle}>
            {!loading ?
                <Card sx={{ flexGrow: 1, p: 1, my: 2, maxWidth: 400 }}>
                    <CardHeader title={`Chat with: ${getOtherUser(_chat).name}`} />
                    {!loading &&
                        <Grid sx={{ maxHeight: '45vh', overflowY: 'auto' }}>
                            {_messages.map((m, idx) => (
                                <ChatMessage key={idx} side={getSide(m)} messages={[m]} sender={getSenderInfo(m)} />
                            ))}
                            <AlwaysScrollToBottom />
                        </Grid>
                    }

                    <Divider sx={{ mt: 2 }} />
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={10}>
                            <TextField
                                inputRef={messageInput}
                                id="message-text"
                                name="message"
                                label="Type something"
                                fullWidth
                                multiline
                                rows={1.5}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            alignItems="center"
                        >
                            <Fab
                                color="primary"
                                sx={{ ml: 1, mt: 2 }}
                                aria-label="add"
                                size="medium"
                                onClick={handleSend}
                            ><Send />
                            </Fab>
                        </Grid>
                    </Grid>
                </Card> : <Card sx={{ flexGrow: 1, p: 1, my: 2, maxWidth: 400 }}>No Chat available</Card>}
        </Container>
    );
}
