import React from "react";
import { Container, Link, Divider, Box, Typography } from "@mui/material";
// import { AccountBox, HelpCenter, Home } from "@mui/icons-material";
// import { useRealmApp } from "../RealmApp";
// import { Link as RLink } from "react-router-dom";

export function Footer() {
  return (
    <MoreInfoTemplateAndDocs />
  );
}

function MoreInfoItem({ children }) {
  return (
    <>
      <Divider sx={{ my: 2 }}></Divider>
      <Container style={{ textAlign: "center", padding: "14px 0px 150px", marginTop: "auto" }}>
        {children}
      </Container>
    </>

  );
}

export function MoreInfoTemplateAndDocs() {
  // const { currentUser } = useRealmApp();
  return (
    <MoreInfoItem>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Link component={RLink} to={"/"}><Home /></Link>
        <Link component={RLink} to={"/page/about"}><HelpCenter /></Link>
        {currentUser && <Link component={RLink} to={"/profile"}><AccountBox /></Link>}
      </Breadcrumbs> */}
      <Box
        sx={{
          display: { xs: 'flex', sm: 'flex' },
          flexDirection: 'column',
          gap: .5,
        }}
      >
        <Typography variant={"body1"} fontWeight={600}>
          Our Location
        </Typography>

         {/* TODO: get address from {store} */}
        <Link href="maps:?q=1084 Taylorsville Rd. Washington Crossing, PA 18977"
          sx={{ mx: 1, px: 1 }} variant="body2"> 
          1084 Taylorsville Rd. Washington Crossing, PA 18977
        </Link>
        <Link sx={{ my: 2 }} color="text.secondary" href="mailto:NINJADUDS4@gmail.com">
          Questions? Email Us
        </Link>
        <Link sx={{ my: 1 }} color="text.secondary" href="/page/about">
          About Us
        </Link>

      </Box>
      <Divider sx={{ m: 3 }} light></Divider>
      <Typography variant="body2" fontWeight={100}>
        Powered by <Link color="text.secondary" target="_blank" href="https://www.swag-shops.com/">SwagShops</Link>
      </Typography>
    </MoreInfoItem>
  );
}