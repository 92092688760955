import { Button, Card, Container, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useRealmApp } from "../RealmApp";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toggleBoolean } from "../../shared/helpers";

export function ResetPasswordPage() {
    const realmApp = useRealmApp();
    const [error, setError] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [resetDone, setResetDone] = React.useState(false);
    const newPassword = React.useRef();
    const { token, tokenId } = useParams();
    const toggleShowPassword = () => setShowPassword(toggleBoolean);

    useDocumentTitle('Reset Password')

    const buttonHandler = React.useCallback(async (event) => {
        event.preventDefault();
        // @ts-ignore
        const data = { newPassword: newPassword.current?.value };
        // console.log(data);
        try {
            await realmApp.emailPasswordAuth.resetPassword({
                password: data.newPassword,
                token,
                tokenId,
            });
            setError('')
            setResetDone(true)
        } catch (ex) {
            console.log('err:', ex.error);
            setError(ex.error);
        }
    },
        [token, tokenId, realmApp.emailPasswordAuth]
    );

    return (
        <Container maxWidth="sm" className="main-container">
            <Card className="auth-card" variant="outlined">
                <Typography style={{ marginBottom: 5 }} variant="h6">Reset password</Typography>
                <TextField
                    id="input-reset-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    label="New Password"
                    variant="outlined"
                    autoComplete={"new-password"}
                    inputRef={newPassword}
                    error={Boolean(error)}
                    helperText={error ?? ""}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant="contained" color="primary" onClick={buttonHandler}>
                    Reset password
                </Button>
                {resetDone ? <div>Reset Complete</div> : ''}
            </Card>
        </Container>
    )

}