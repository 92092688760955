/// <reference path="../typedefs.js" />
import React from "react";
// import * as Realm from "realm-web";
import Debug from "debug";
// import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
// import Config from "../shared/Config";
// const { dataSourceName } = Config();
const debug = Debug("hooks:useSellers");


export function useSellers(store) {
  // const realmApp = useRealmApp();
  const { currentUser } = useRealmApp();
  const [loading, setLoading] = React.useState(true);
  /** @type {useState<User[]>} */
  const [sellers, setSellers] = React.useState([]);

  React.useEffect(() => {
    currentUser.callFunction("getSellers", {query: {storeId: store._id}})
    .then(res => {
        debug({res})
        setSellers(res.documents);
        setLoading(false);
    });
  }, [currentUser, store]);

  return {
    sellers,
    currentUser,
    loading
  };
}
