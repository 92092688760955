import React from "react";
import axios from "axios";
import Debug from "debug";
import Config from "../shared/Config";
const debug = Debug("hooks:useHttpEndpoint");
const { appId, endpointHost } = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint`
const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

const CACHE = {}

export function useHttpEndpoint(path, { queryParams, cache = true }) {
    /** @type {useState<any>} */
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const loadData = React.useCallback(() => {
        const config = { url: `${endpointUrl}/${path}`, headers };
        if (queryParams) {
            const searchParams = new URLSearchParams(queryParams);
            config.url = `${config.url}?${searchParams.toString()}`;
        }
        
        if (CACHE[config.url]) {
            debug('MEM CACHE HIT', queryParams);
            setData(CACHE[config.url]);
            setLoading(false);
        } else {
          return axios(config)
                .then(response => {
                    const { data } = response;
                    debug(`HTTPEndpoint: fetch`, queryParams, { data });
                    setData((prev) => {
                        let oldDocs = [];
                        let docs = data.documents || [];

                        if (queryParams.skip && prev?.documents && prev?.documents.length) {
                                oldDocs = prev.documents;
                                docs = data.documents;
                        }
                        // debug('old:', oldDocs, 'new:', docs, queryParams.skip);
                        const alldocs = [...oldDocs, ...docs];
                        const results = {
                            count: data.count,
                            total: data.total,
                            documents : alldocs
                        }
                        if (cache) {
                            // debug('cachekey:', config.url)
                            CACHE[config.url] = results;
                        }
                        return results;
                    });
                    setLoading(false);
                })
                .catch(err => {
                    console.log({ err });
                    setLoading(false);
                    // setError(err) // causes infinite loop
                });
        }
    }, [path, cache, queryParams]);

    return { loadData, loading, data };
}
