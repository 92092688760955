/// <reference path="../typedefs.js" />
import React from "react";
import * as Realm from "realm-web";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
const { dataSourceName } = Config();

export function useOrders() {
  const realmApp = useRealmApp();
  const [orderSaved, setOrderSaved] = React.useState(false);

  // Get a client object for the order collection
  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "orders"
  });

  /** @param {object} order */
  const saveOrder = async (order) => {
    if (order.item) {
      order._id = new Realm.BSON.ObjectID();
      order.created_ts = new Date();
      order.sellerId = order.item._partition;
      order.payerId = realmApp.currentUser.id;
      order.payerProviderType = realmApp.currentUser.providerType;
      order.storeId = order.item.storeId;
      // order.owners = [realmApp.currentUser.id, order.item._partition];
      try {
        // console.log({ order })
        await collection.insertOne(order);
        setOrderSaved(true);
      } catch (err) {
        console.error(err);
        if (err.error.match(/^Duplicate key error/)) {
          console.warn(
            `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
          );
        }
      }
    }
  };

  /**
   * @param {Order} order 
   * @param {string} status 
   */
  const updateOrderStatus = async (order, status) => {
    await collection.updateOne(
      { _id: order._id },
      { $set: { status } }
    );
  };

  /**
   * @param {Order} order 
   * @param {string} status 
   */
  const updateOrderItemStatus = async (order, status) => {
    await collection.updateOne(
      { _id: order._id },
      { $set: { "item.status": status } }
    );
  };

  // Delete a given todo
  // const deleteOrder = async (order) => {
  //   await collection.deleteOne({ _id: order._id });
  // };

  return {
    orderSaved,
    saveOrder,
    updateOrderStatus,
    updateOrderItemStatus
  };
}
