import React from "react";
import { Container } from "@mui/material";
import { EmailRegisterForm } from "../forms/EmailRegisterForm";
import { useRealmApp } from "../RealmApp";

export function Register({setShowLogin}) {
  const origin = window.location.origin;
  console.log({origin});
  const realmApp = useRealmApp();

  return (
    <Container maxWidth="sm" className="main-container">
        <EmailRegisterForm realmApp={realmApp} setShowLogin={setShowLogin}  />
    </Container>
  );
}
