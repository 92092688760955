import React, { useCallback } from "react";
import { useUploady } from "@rpldy/uploady";
import { Button } from "@mui/material";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
// import { getUploadURL } from "../../../shared/getEndpoint";

/**
 * @param {object} props
 * @param {string} props.buttonTitle
 * @param {boolean} props.uploaded
 * @param {any} props.children
 */
export function ProfileUploadButton({ buttonTitle, uploaded, children }) {
  const { showFileUpload } = useUploady();
  
  const onButtonClick = useCallback((e) => {
    showFileUpload();
  }, [showFileUpload]);

  return <Button
    size="small"
    style={{ float: 'right' }}
    disabled={uploaded}
    variant="outlined"
    endIcon={<PhotoCamera />}
    onClick={onButtonClick}>
    {buttonTitle}
    {children}
  </Button>;
}
