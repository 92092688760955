import { Avatar, Grid, Typography, useTheme } from "@mui/material";
import { avatarImageURL } from "../../shared/helpers";
import React from "react";

function ChatMessage({ side, messages, sender }) {
    const theme = useTheme();
    const avatarSize = { width: 28, height: 28 }
    // console.log({sender});

    const dateOptions = {
        weekday: "short",
        // year: "2-digit",
        month: "short",
        day: "numeric"
    };

    function getChatStyle(side) {
        if (side === 'left') {
            return {
                wordBreak: "break-word",
                display: "inline-block",
                px: 2,
                my: .3,
                py: .6,
                borderRadius: '1em',
                borderBottomLeftRadius: '2px',
                // @ts-ignore
                backgroundColor: theme.palette.neutral.main,
                color: '#fff'
            }
        } else {
            return {
                wordBreak: "break-word",
                display: "inline-block",
                px: 1,
                my: .3,
                py: .5,
                borderRadius: '1em',
                borderBottomRightRadius: '2px',
                backgroundColor: theme.palette.secondary.main,
                color: '#000'
            }
        }
    }

    return (<>
        <Grid container spacing={1}>
            {side === "left" && (
                <Grid item xs={2}>
                    <Avatar alt={sender?.name} src={avatarImageURL(sender?.imageUrl)} sx={avatarSize}>{sender?.name ? sender?.name[0]: '' }</Avatar>
                </Grid>
            )}
            <Grid item xs={10}>
                {messages.map((msg, i) => {
                    return (
                        <Typography align={side} key={i} component='div'>
                            <Typography align={side} sx={getChatStyle(side)}>
                                {msg.text}
                            </Typography>
                            <Typography align={side} variant="caption" sx={{display: "block"}}>
                              {msg.created_ts.toLocaleDateString([], dateOptions)}&nbsp; 
                              {msg.created_ts.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                            </Typography>
                        </Typography>
                    );
                })}
            </Grid>
            {side === "right" && (
                <Grid item xs={2}>
                    <Avatar alt={sender?.name} src={avatarImageURL(sender?.imageUrl)} sx={avatarSize}>{sender?.name ? sender?.name[0]: ''}</Avatar>
                </Grid>
            )}
        </Grid>
    </>);
}

export default ChatMessage;