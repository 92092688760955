import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

export default function UnReadChatBanner({currentUser}) {
    const [unreadChat, setUnreadChat] = React.useState(false);

    React.useEffect(() => {
        currentUser.callFunction('getUnreadChats')
          .then(res => {
            setUnreadChat(Boolean(res));
          });
      }, [currentUser])

    return (
        <>
        {unreadChat &&
            <Typography sx={{ mt: 2 }} variant={"subtitle1"}>You have <Link to={'/chat'}> new Chat messages</Link> </Typography>
          }
        </>
    )
}