import React from "react";
import { useRealmApp } from "./RealmApp";
import { AppTopBar } from "./controls/AppTopBar";
import AppDrawer from "./controls/AppDrawer";
import { Container, LinearProgress } from "@mui/material";
import { LoginDialog } from "./controls/LoginDialog";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Footer } from "./controls/Footer";

export default function Layout({ store, loading, setShowLogin, showLogin, setShowSearch }) {
  const { currentUser, logOut } = useRealmApp();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <div className="App">
      <AppTopBar setDrawerOpen={setDrawerOpen} store={store} currentUser={currentUser} setShowLogin={setShowLogin} />
      <AppDrawer
        store={store}
        currentUser={currentUser}
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        setShowLogin={setShowLogin}
        setShowSearch={setShowSearch}
        logOut={logOut} />
      {loading ? <LinearProgress /> :
        <Container id="app-container" disableGutters={true}>
          <LoginDialog open={showLogin} store={store} setShowLogin={setShowLogin} />
          <Outlet />
          <Footer />
        </Container>}
      <ScrollRestoration />
    </div>
  );
}