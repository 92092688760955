import React from "react";
import { CloseRounded } from "@mui/icons-material";
import { Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";

export function EditEmailDialog({ setOpen, open, currentUser }) {
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [emailError, setEmailError] = React.useState("");
    const [confirmError, setConfirmError] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [emailToVerify, setEmailToVerify] = React.useState("");

    function handleClose() {
        setOpen(false);
    };

    async function onConfirmFormSubmit({ confirmCode }) {
        setConfirmError('');
        setSubmitting(true);
        // set email to confirmed
        try {
            const res = await currentUser.callFunction('verifyEmailWithCode', { emailToVerify, confirmCode });
            if (res.result === 'verified') {
                setSubmitting(false);
                handleClose();
                setShowConfirm(false);
            } else {
                throw Error('Confirm code invalid. Try again.');
            }
        } catch (ex) {
            setConfirmError(ex.message);
            setSubmitting(false);
        }
    }

    async function onEditEmailSubmit({ newEmail }) {
        setEmailError('');
        setSubmitting(true);
        // send confirm email
        try {
            await currentUser.callFunction('sendEmailUpdateConfirmation', { newEmail });
            setEmailToVerify(newEmail);
            setShowConfirm(true);
            setSubmitting(false);
        } catch (ex) {
            setEmailError(ex.error);
            setSubmitting(false);
        }

    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                <IconButton style={{ float: 'right' }} onClick={() => setOpen(false)}>
                    <CloseRounded />
                </IconButton>
                Update Email
            </DialogTitle>
            <DialogContent>
                <Card>
                        <form
                            className="swag-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                // @ts-ignore
                                const formData = new FormData(e.target);
                                // @ts-ignore
                                const { newEmail } = Object.fromEntries(formData.entries());
                                onEditEmailSubmit({ newEmail });
                            }}
                        >
                            <Typography variant="subtitle1">
                                Use this form to update your email address.
                            </Typography>
                            <TextField
                                sx={{ mt: 2 }}
                                id="input-add-email"
                                name="newEmail"
                                label="New Email Address"
                                variant="outlined"
                                error={Boolean(emailError)}
                                helperText={emailError}
                            />
                            {!showConfirm &&
                            <Button type="submit" variant="contained" color="primary">Submit
                                {submitting ? <CircularProgress color="info" sx={{ ml: 1 }} size={20} /> : ""}
                            </Button>}
                        </form>
                        {showConfirm &&
                        <form
                            className="swag-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                // @ts-ignore
                                const formData = new FormData(e.target);
                                // @ts-ignore
                                const { confirmCode } = Object.fromEntries(formData.entries());
                                onConfirmFormSubmit({ confirmCode });
                            }}
                        >
                            <Typography>
                                Check your email and enter code
                            </Typography>
                            <TextField
                                id="input-confirmCode"
                                name="confirmCode"
                                label="Confirmation code"
                                variant="outlined"
                                autoComplete="off"
                                error={Boolean(confirmError)}
                                helperText={confirmError}
                            />
                            <Button type="submit" variant="contained" color="primary">Submit
                                {submitting ? <CircularProgress color="info" sx={{ ml: 1 }} size={20} /> : ""}
                            </Button>
                        </form>}
                    
                </Card>
            </DialogContent>
        </Dialog>
    )
}