import { Card, CardActions, IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import React from "react";
import { itemImageUrls } from "../../../shared/helpers";
import ItemImage from "../../controls/ItemImage";
import { Edit } from "@mui/icons-material";
import { AddImage } from "./AddImage";

export function EditItemImageList({ item, currentUser }) {
    const [imgUrls, setImgUrls ] = React.useState(itemImageUrls(item));

    return (
        <Card>
            <ImageList style={{ maxHeight: 300 }}
                sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fill,minmax(85%,1fr)) !important",
                    gridAutoColumns: "minmax(85%, 1fr)"
                }}>
                {imgUrls.map((url, idx) => (
                    <ImageListItem key={idx}>
                        <ItemImage url={url} />
                        <ImageListItemBar
                            sx={{
                                display: 'none', // HIDE Image Edit
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            actionIcon={
                                <IconButton size="small"
                                    onClick={() => console.log('click', idx)}
                                    sx={{ color: 'white' }}
                                    aria-label={'Edit'}
                                >Edit <Edit />
                                </IconButton>
                            }
                            actionPosition="left"
                            position="top"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <CardActions>
                <AddImage item={item} imgUrls={imgUrls} setImgUrls={setImgUrls} currentUser={currentUser} />
            </CardActions>
        </Card>
    )

}
