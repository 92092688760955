import React from "react";
import Debug from "debug";
import { Avatar, Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import ProfileImageUpload from "./ProfileImageUpload";
import { avatarImageURL, regionList } from "../../../shared/helpers";
import { Link as RLink } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { EditEmailDialog } from "./EditEmailDialog";
const debug = Debug("pages:ProfileForm");

export default function ProfileForm({ currentUser, upsertUser, user, store }) {
    const [dirty, setDirty] = React.useState(false);
    const [openAddEmailDialog, setOpenAddEmailDialog] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const { providerType } = currentUser;
    const anonUser = (providerType === 'anon-user');

    const { fullname, address, imageUrl } = user || {};
    const roles = []
    // console.log('user:', store, user.sellerStores?.includes(store._id)); // need for Seller invite

    if (user?.sellerStores?.includes(store._id)) {
        roles.push('seller')
    }

    const saveProfile = async (profile) => {
        try {
            debug('saveProfile', profile);
            upsertUser(profile);
        } catch (err) {
            console.error({ err });
        }
    };

    function handleInput(e) {
        setDirty(true);
    };

    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = currentUser?.profile?.email;
        const providerType = currentUser?.providerType;
        const {
            fullname,
            street,
            city,
            region,
            postalcode
            // @ts-ignore
        } = Object.fromEntries(formData.entries());

        const profile = {
            fullname,
            email,
            providerType,
            address: { street, city, region, postalcode }
        };
        await saveProfile(profile);
        setSnackbarOpen(true);
        setDirty(false);
        currentUser.refreshCustomData(); // prevent stale customData
    }

    return (
        <>
            {!anonUser ?
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={4000}
                        onClose={() => setSnackbarOpen(false)}
                        message="Saved your Profile"
                    />
                    <Card sx={{ p: 2 }} variant="outlined">
                        <Typography component="h4" variant="h5" sx={{ mb: 2 }}>
                            Profile {roles.length ? <>({roles.join(', ')})</> : ''}
                            <span style={{ fontSize: 12, marginLeft: 6 }}>ID: {currentUser.id.substring(19, 30)}</span>
                            {imageUrl ?
                                <>
                                    <ProfileImageUpload upsertUser={upsertUser} currentUser={currentUser}>
                                        <Avatar style={{ float: 'right' }} alt={fullname ? fullname[0][0] : ''} src={avatarImageURL(imageUrl)} /> :
                                    </ProfileImageUpload>
                                </>
                                : <ProfileImageUpload buttonTitle={"Upload Pic"} upsertUser={upsertUser} currentUser={currentUser} />
                            }
                        </Typography>
                        <form className="swag-form" onSubmit={handleSubmit}>
                            <TextField
                                defaultValue={fullname}
                                id="input-fullname"
                                name="fullname"
                                label="Full name"
                                variant="outlined"
                                onChange={handleInput}
                            />
                            <TextField
                                defaultValue={currentUser?.profile?.email}
                                id="input-email"
                                size="small"
                                name="email"
                                label="Email"
                                variant="outlined"
                                disabled={true}
                            />
                            {user?.email2 &&
                              <Typography variant={"subtitle2"}>Primary Email: {user.email2}</Typography>
                            }
                            <Button size="small" sx={{maxWidth: 180}} variant={"outlined"} endIcon={<Edit />} onClick={() => setOpenAddEmailDialog(true)}>Update Email</Button>
                            <TextField
                                defaultValue={address?.street}
                                size="small"
                                id="input-street"
                                name="street"
                                label="Street address"
                                variant="outlined"
                                onChange={handleInput}
                            />
                            <TextField
                                defaultValue={address?.city}
                                size="small"
                                id="input-city"
                                name="city"
                                label="City"
                                variant="outlined"
                                onChange={handleInput}
                            />
                            <FormControl size="small">
                                <InputLabel id="region-label">State</InputLabel>
                                <Select
                                    id="select-region"
                                    size="small"
                                    labelId="region-label"
                                    name="region"
                                    label="State"
                                    defaultValue={address?.region || ''}
                                    onChange={handleInput}>
                                    <MenuItem key="" value="">----</MenuItem>
                                    {regionList.map((val) => (
                                        <MenuItem key={String(val)} value={val}>{val}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                defaultValue={address?.postalcode}
                                size="small"
                                id="input-postalcode"
                                name="postalcode"
                                label="Zip"
                                variant="outlined"
                                onChange={handleInput}
                            />
                            <Button disabled={!dirty || anonUser} type="submit" variant="contained" color="primary">
                                Update
                            </Button>
                        </form>
                    </Card>
                    <EditEmailDialog
                        currentUser={currentUser}
                        open={openAddEmailDialog}
                        setOpen={setOpenAddEmailDialog}
                    />
                </> :
                <Box sx={{ m: 4, textAlign: 'center' }}>
                    <Typography sx={{ mb: 2 }}>Welcome, Guest User. <br />
                        Please register for an account.
                    </Typography>
                    <Button variant="outlined" component={RLink} to={'/signup'}> Register</Button>
                </Box>
            }
        </>
    );
}
