import React from "react";
import {
    Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton,
    ListItemText, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { useRealmApp } from "../../components/RealmApp";
import { timeAgo } from "../../hooks/util-hooks";
import { Link } from "react-router-dom";

export default function ConversationList({ chats }) {
    const realmApp = useRealmApp();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogTitleStyle = { minWidth: 320 }
    if (!fullScreen) {
        dialogTitleStyle.minWidth = 520
    }

    function getOtherUser(chat) {
        return chat.message.usersInfo.find(u => u.id !== realmApp.currentUser.id);
    }

    return (
        <>
            <List sx={{ width: '100%', maxWidth: 480, bgcolor: 'background.paper' }}>
                {chats.map((c, idx) => (
                    <span key={idx}>
                        <ListItem disablePadding={true} alignItems="flex-start">
                            <ListItemButton component={Link} to={`/chat/c/${c._id}`} disabled={c.disabled}>
                                <ListItemAvatar>
                                    <Avatar alt={getOtherUser(c).name}>{getOtherUser(c).name ? getOtherUser(c).name[0] : ''}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ color: 'primary' }}
                                    primary={getOtherUser(c).name}
                                    secondary={
                                        <>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {c.message.sender === "you" && <>You: </>}
                                            </Typography>
                                            {c.message.text} - {timeAgo(c.message.created_ts)} ago.
                                        </>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </span>
                ))}
            </List>
        </>);
}