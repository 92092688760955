/// <reference path="../typedefs.js" />
import React from "react";
import * as Realm from "realm-web";
import Debug from "debug";
const debug = Debug("hooks:useDraftItems");

export function useDraftItems() {
   /** @type {useState<Listing[]>} */
  const [drafts, setDrafts] = React.useState([]);

  /**
   * @param {Store} store 
   */
  const createDraftItem = (store) => {
    /** @type {Listing} */
    const draftItem = {
      _id: new Realm.BSON.ObjectID(),
      _partition: "",
      created_ts: new Date(),
      updated_ts: new Date(),
      storeId: `${store._id}`,
      price: 0,
      retailPrice: 0,
      shippingFee: 0,
      inventoryCount: 1,
      condition: "",
      status: "active",
      title: "",
      imageUrl: "",
      imageUrl2: "",
      category: "",
      category2: "",
      consignor: ""
    };
    // @ts-ignore
    setDrafts((d) => [...d, draftItem]);
  };

  const setDraftImageUrl = (draft, imageUrl) => {
    let fieldName = 'imageUrl'
    if (draft.imageUrl) {
      fieldName = 'imageUrl2';
    }
    if (draft.imageUrl2) {
      fieldName = 'imageUrl3';
    }
    if (draft.imageUrl3) {
      fieldName = 'imageUrl4';
    }
    if (draft.imageUrl4) {
      fieldName = 'imageUrl5';
    }
    if (draft.imageUrl5) {
      fieldName = 'imageUrl6';
    }
    console.log('setDraftImageUrl', {[`${fieldName}`]: imageUrl})

    // @ts-ignore
    setDrafts((oldDrafts) => {
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
      return [
        ...oldDrafts.slice(0, idx),
        { ...oldDrafts[idx], [`${fieldName}`]: imageUrl },
        ...oldDrafts.slice(idx + 1),
      ];
    });
  };

  const setDraftTextField = (draft, field) => {
    // @ts-ignore
    setDrafts((oldDrafts) => {
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
       return [
        ...oldDrafts.slice(0, idx),
        { ...oldDrafts[idx], [field.name]: field.value },
        ...oldDrafts.slice(idx + 1),
      ];
    });
  };

  const setDraftNumberField = (draft, field) => {
    // @ts-ignore
    setDrafts((oldDrafts) => {
      const _num = Number(field.value);
      const num = isNaN(_num) ? 0 : _num
      debug(`set ${field.name}`, field.value, num);
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
      return [
        ...oldDrafts.slice(0, idx),
        { ...oldDrafts[idx], [field.name]: num },
        ...oldDrafts.slice(idx + 1),
      ];
    });
  };


  const deleteDraftItem = (draft) => {
    // @ts-ignore
    setDrafts((oldDrafts) => {
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
      return [...oldDrafts.slice(0, idx), ...oldDrafts.slice(idx + 1)];
    });
  };

  return {
    draftItems: drafts,
    setDraftTextField,
    setDraftNumberField,
    createDraftItem,
    setDraftImageUrl,
    deleteDraftItem,
  };
}
