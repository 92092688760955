import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { Link, useLoaderData } from "react-router-dom";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export function Page({ store }) {
    const data = useLoaderData();
    useDocumentTitle(`${data[0]?.title} - ${store.name}`);

    return (
        <main>
            <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
                <Container maxWidth="sm">
                    <div dangerouslySetInnerHTML={{ __html: `<iframe title='${data[0].title}' frameborder='0' height='550' width='100%' src='${data[0].link}?embedded=true' />` }} />
                    <ul>
                        <li><Link to={"/"}>Home</Link></li>
                    </ul>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center">
                    </Stack>
                </Container>
            </Box>
        </main>
    );
}