/// <reference path="../typedefs.js" />
import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";

const { dataSourceName } = Config();

export function useMyOrders({partition}) {
  const realmApp = useRealmApp();
  /** @type {useState<Order[]>} */
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "orders",
  });

  const userId = realmApp.currentUser.id

  React.useEffect(() => {
    collection.aggregate([
      { "$match": { [partition]: userId } },
      {$sort: {created_ts: -1}},
      { $limit : 300 }
    ]).then((res) => {
      setOrders(res);
      setLoading(false);
    });
  }, [collection, userId, partition]);

  return {
    loading,
    orders
  };
}
