import React from "react";
import {
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  FormHelperText,
  FormControlLabel,
  Switch
} from "@mui/material";
import { DeleteForeverRounded, Publish } from "@mui/icons-material";
import { useListing } from "../../../hooks/useListing";
import { itemConditions } from "../../../shared/helpers";

export function EditItemForm({ item, setOpen, categories, sizes, consignors, store }) {
  const [isFormDirty, setFormDirty] = React.useState(false);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [chargeTax, setChargeTax] = React.useState(Boolean(item.salesTaxRate) || false);
  const [salesTaxRate, setSalesTaxRate] = React.useState(item.salesTaxRate || 0);
  const [showDiscount, setShowDiscount] = React.useState(item.applyDiscount || false);
  const [price, setPrice] = React.useState(item.price);
  const [discountRate, setDiscountRate] = React.useState(item.discountRate || 0);
  const [discountPrice, setDiscountPrice] = React.useState(item.discountPrice || 0);
  const [retailPrice, setRetailPrice] = React.useState(item.retailPrice);
  const { updateListingPart } = useListing();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const noErrors = {
    email: null,
    password: null,
    other: null,
  };

  const [error] = React.useState(noErrors);

  /** @param {any} e */
  function handleClose(e) {
    setArchiveOpen(false);
    // setOpen(false);
  };

  /** @param {ClickEvent} e */
  function handleInputChange(e) {
    setFormDirty(true);
  };

  /** @param {ClickEvent} e */
  function handlePriceChange(e) {
    const _price = e.target.value;
    const price = isNaN(_price) ? 0 : _price;
    setPrice(price);
    setFormDirty(true);
  };

  /** @param {ClickEvent} e */
  function handleRetailPriceChange(e) {
    const _num = e.target.value;
    const num = isNaN(_num) ? 0 : _num;
    setRetailPrice(num);
    setFormDirty(true);
  };

  /** @param {any} e */
  async function handleStatusUpdate(e) {
    const newStatus = item.status === 'active' ? 'archived' : 'active';
    updateListingPart(item._id, { status: newStatus, updated_ts: new Date() });
    item.updated_ts = new Date(); // updates UI
    item.status = newStatus; // updates UI
    setArchiveOpen(false);
  }

  /** @param {ClickEvent} e */
  function handleSalesTaxChange(e) {
    if (isNaN(e.target.value)) {
      return
    }
    if (Number(e.target.value) > 12) { // max value for Salestax
      return
    }
    setFormDirty(true);
    setSalesTaxRate(e.target.value);
  };

  /** @param {ClickEvent} e */
  function handleDiscountChange(e) {
    if (isNaN(e.target.value)) {
      return
    }
    if (Number(e.target.value) > 99) {
      return
    }
    setFormDirty(true);
    const discount = Number(e.target.value / 100);
    setDiscountRate(e.target.value);
    const discountprice = price - (price * discount);
    // console.log({ discount, discountprice });
    setDiscountPrice(discountprice);
  };

  /** @param {any} e */
  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      title,
      price,
      retailPrice,
      shippingFee,
      salesTaxRate,
      category,
      category2,
      size,
      consignor,
      inventoryCount,
      applyDiscount,
      discountRate,
      description,
      condition
      //@ts-ignore
    } = Object.fromEntries(formData.entries());

    // console.log({ applyDiscount })
    const _price = Number(price);
    const _retailPrice = Number(retailPrice);
    const _discountRate = Number(discountRate);
    const _discountPrice = Number(discountPrice);
    const _shippingFee = Number(shippingFee);
    const _count = Number(inventoryCount);
    const _salesTaxRate = Number(salesTaxRate);
    item.updated_ts = new Date(); // updates UI
    const doc = {
      title,
      description,
      consignor,
      price: isNaN(price) ? 0 : _price,
      retailPrice: isNaN(retailPrice) ? 0 : _retailPrice,
      shippingFee: isNaN(shippingFee) ? 0 : _shippingFee,
      inventoryCount: isNaN(inventoryCount) ? 1 : _count,
      applyDiscount: applyDiscount === "on" ? true : false,
      discountRate: isNaN(discountRate) ? 0 : _discountRate,
      discountPrice: isNaN(discountPrice) ? 0 : _discountPrice,
      salesTaxRate: isNaN(salesTaxRate) ? 0 : _salesTaxRate,
      category,
      category2,
      size,
      condition,
      updated_ts: new Date()
    };
    try {
      await updateListingPart(item._id, doc);
      setSnackbarOpen(true);
      setFormDirty(false);
    } catch (ex) {
      console.error(ex);
    }
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message="Listing updated"
      />
      <form className="auth-form" onSubmit={handleSubmit}>
        <TextField
          id="input-title"
          defaultValue={item.title}
          name="title"
          label="Title *"
          variant="outlined"
          error={Boolean(error.title)}
          onChange={handleInputChange}
          helperText={error.title ?? ""}
        />
        <TextField
          multiline={true}
          id="input-description"
          defaultValue={item.description}
          name="description"
          label="Description"
          variant="outlined"
          error={Boolean(error.description)}
          onChange={handleInputChange}
          helperText={error.description ?? ""}
        />
        <div>
          <TextField
            id="input-price"
            name="price"
            value={price}
            label="Price *"
            variant="outlined"
            sx={{ maxWidth: 150 }}
            error={Boolean(error.price)}
            onChange={handlePriceChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            helperText={error.price ?? ""}
          />
          <TextField
            id="input-retailprice"
            name="retailPrice"
            value={retailPrice}
            label="Retail Price"
            variant="outlined"
            sx={{ ml: 2, maxWidth: 140 }}
            error={Boolean(error.retailPrice)}
            onChange={handleRetailPriceChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            helperText={error.retailPrice ?? ""}
          />
        </div>

        <FormControlLabel
          control={
            <Switch 
              name="applyDiscount"
              color="info"
              onClick={() => {
                setFormDirty(true);
                setShowDiscount(prev => prev ? false : true);
              }}
              checked={showDiscount}
            />}
          label="Apply discount"
        />
        {showDiscount ?
          <div>
            <TextField
              id="input-discountRate"
              name="discountRate"
              label="Discount %"
              sx={{ maxWidth: 150 }}
              defaultValue={discountRate}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="start">% Off</InputAdornment>,
              }}
              onChange={handleDiscountChange} />
            <TextField
              id="input-discountValue"
              name="discountValue"
              label="Discount Price"
              disabled={true}
              sx={{ maxWidth: 140, ml: 2 }}
              value={discountPrice === 0 ? price : discountPrice.toFixed(2)}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </div>
          : ""}
        {sizes?.length > 0 &&
          <FormControl>
            <InputLabel id="size-label">Size</InputLabel>
            <Select
              id="select-size"
              labelId="size-label"
              name="size"
              label="Size"
              defaultValue={item.size || ''}
              onChange={handleInputChange}>
              <MenuItem key="" value="na">-- n/a --</MenuItem>
              {sizes.map((item) => (
                <MenuItem key={String(item.key)} value={item.key}>{item.key}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {categories?.length > 0 &&
          <FormControl>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              id="select-category"
              labelId="category-label"
              name="category"
              label="Category"
              defaultValue={item.category}
              onChange={handleInputChange}>
              {categories.map((cat) => (
                <MenuItem key={String(cat.slug)} value={cat.slug}>{cat.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {categories?.length > 0 &&
          <FormControl>
            <InputLabel id="category2-label">2nd Category</InputLabel>
            <Select
              id="select-category2"
              labelId="category2-label"
              name="category2"
              label="2nd Category"
              defaultValue={item.category2 || ''}
              onChange={handleInputChange}>
              <MenuItem key="" value="">-- n/a --</MenuItem>
              {categories.map((cat) => (
                <MenuItem key={String(cat.slug)} value={cat.slug}>{cat.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>optional</FormHelperText>
          </FormControl>
        }
        {itemConditions?.length > 0 &&
          <FormControl>
            <InputLabel id="condition-label">Condition</InputLabel>
            <Select
              id="select-condition"
              labelId="condition-label"
              name="condition"
              label="Condition"
              defaultValue={item.condition || ""}
              onChange={handleInputChange}>
              <MenuItem value="">-- n/a --</MenuItem>
              {itemConditions.map((condition) => (
                <MenuItem key={String(condition.code)} value={condition.code}>{condition.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {store.enableInventory &&
          <TextField
            id="input-count"
            name="inventoryCount"
            label="Inventory Count"
            defaultValue={item.inventoryCount}
            variant="outlined"
            style={{ width: "100%" }}
            onChange={handleInputChange} />
        }
        {store.isConsignment &&
          <FormControl>
            <InputLabel id="consignor-label">Consignor</InputLabel>
            <Select
              id="select-consignor"
              labelId="consignor-label"
              name="consignor"
              defaultValue={item.consignor || ""}
              label="Consignor"
              onChange={handleInputChange}>
              {consignors.map((i) => (
                <MenuItem key={String(i._id)} value={i.venmo}>{i.name} ({i.venmo})</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {store.defaultShippingFee ?
          <TextField
            id="input-shippingFee"
            name="shippingFee"
            label="Shipping Fee"
            defaultValue={item.shippingFee ? item.shippingFee.toFixed(2) : ""}
            variant="outlined"
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={handleInputChange} />
          : <>Shipping fees disabled</>}

        <FormControlLabel
          control={
            <Switch name="chargeTax"
              color="info"
              onClick={(e) => {
                setFormDirty(true);
                if (!e.target['checked']) {
                  // setSalesTaxRate(0);
                }
                setChargeTax(prev => prev ? false : true);
              }}
              checked={chargeTax}
            />}
          label="Charge Tax"
        />
        {chargeTax ?
          <>
            <TextField
              id="input-salesTaxRate"
              name="salesTaxRate"
              label="Sales Tax"
              sx={{ maxWidth: 150 }}
              defaultValue={salesTaxRate}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              onChange={handleSalesTaxChange} />
          </>
          : ""}
        <FormControl>
          <Typography component="p">Status: {item.status}</Typography>
          <Typography component="p">Updated: {new Date(item.updated_ts).toLocaleDateString()} -
            {" " + new Date(item.updated_ts).toLocaleTimeString()}
          </Typography>
        </FormControl>
        <span>
          <Button disabled={!isFormDirty || item.status === 'archived'} style={{ marginRight: 12 }} type="submit" variant="contained" color="primary">
            Save <Publish />
          </Button>
          {item.status === 'active' &&
            <Button variant="contained" color="secondary"
              onClick={() => setArchiveOpen(true)}> Archive
              <DeleteForeverRounded />
            </Button>}
          {item.status === 'archived' &&
            <Button variant="contained" color="warning"
              onClick={() => setArchiveOpen(true)}> Undo Archive
              <DeleteForeverRounded />
            </Button>}
        </span>
      </form>
      <Dialog onClose={handleClose} open={archiveOpen} fullWidth={false}>
        <DialogTitle>{item.status === 'archived' && "Un-"}Archive item?</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 25 }}>
            {item.title}
          </div>
          <Button onClick={handleStatusUpdate} variant="contained">
            Ok
          </Button>
          <Button
            onClick={handleClose}
            style={{ marginLeft: 8 }} variant="outlined">Cancel</Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
