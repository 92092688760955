import React from "react"; 

/**
 * @param {string} title 
 */
export function useDocumentTitle(title) {
    React.useEffect(() => {
      const prevTitle = document.title
      document.title = title
      return () => {
        document.title = prevTitle
      }
    }, [title])
  }