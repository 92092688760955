import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { timeAgo } from "../../../hooks/util-hooks";
import ViewOrder from "./ViewOrder";
import { Link } from "react-router-dom";

export function OrdersTable({ items, currentUser, store, isSales = false }) {
  const [open, setOpen] = React.useState(false);
  /** @type {useState<Order>} */
  const [order, setOrder] = React.useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogTitleStyle = {minWidth:320}
  if (!fullScreen) {
      dialogTitleStyle.minWidth = 520
  }

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
        <TableContainer component={Paper}>
          <Table aria-label="listing table">
            <TableHead>
              <TableRow>
                <TableCell sortDirection={"desc"}>Placed</TableCell>
                <TableCell align="left" variant="head">Item</TableCell>
                {isSales ? <TableCell>Status</TableCell>:null}
                <TableCell>Payment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!items.length && 
              <TableRow>
                <TableCell>Nothing here... <Link to={'/'}>Go buy some stuff</Link></TableCell>
              </TableRow>}
              {items.map((i) => (
                <TableRow hover tabIndex={-1} key={i._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    setOpen(true)
                    setOrder({ ...i })
                  }}>
                  <TableCell>{timeAgo(i.payment.create_time)}</TableCell>
                  <TableCell scope="row">
                    {i.item.title} ${i.item.price}
                  </TableCell>
                  {isSales ? 
                  <TableCell>
                    {i.item.status}
                  </TableCell>:null}
                  <TableCell>
                    ${i.payment.purchase_units[0]?.amount.value}  ({i.data.paymentSource}) <br />
                    {i.item._partition === currentUser.id &&
                      <>
                        {currentUser.customData.sellerStores?.includes(store?._id) &&
                        <div>
                          Consignor: {i.item.consignor}
                        </div>
                        }
                        <div>
                          Payer: {i.payment.payer.name.given_name} {i.payment.payer.name.surname}
                        </div>
                      </>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
            <DialogTitle sx={dialogTitleStyle}>{currentUser.customData.sellerStores?.includes(store?._id) ? "Sold Item": "Order"}
              <IconButton style={{ float: 'right' }} onClick={() => setOpen(false)}>
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ViewOrder order={order} setOrder={setOrder} currentUser={currentUser} store={store}></ViewOrder>
            </DialogContent>
          </Dialog>
        </TableContainer>
  );
}
