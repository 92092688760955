import Config from "../shared/Config";
import useAxios from "axios-hooks";
const { appId, endpointHost } = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint`
const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

export function useAxiosEndpoint(path, { queryParams, useCache = false }) {
    const config = { url: `${endpointUrl}/${path}`, headers };
    if (queryParams) {
        const searchParams = new URLSearchParams(queryParams);
        config.url = `${config.url}?${searchParams.toString()}`;
    }
    const [{ data, loading, error }, refetch ] = useAxios(config, {useCache})
    return { loading, data, error, refetch };
}
