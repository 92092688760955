// @ts-check
/// <reference path="../../../typedefs.js" />
import React from "react";
import { Cancel, Check, Done, KeyboardReturn, MoreVert } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { BSON } from "realm-web";
import { useOrders } from "../../../hooks/useOrders";
import { timeAgo } from "../../../hooks/util-hooks";
import ItemImage from "../../controls/ItemImage";

/**
 * @param {object} props
 * @param {Order} props.order
 * @param {Function} props.setOrder
 * @param {Store} props.store
 * @param {any} props.currentUser
 */
function ViewOrder({ order, setOrder, currentUser, store }) {
  const { updateOrderItemStatus } = useOrders();
  const [loading, setLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  /**
   * 
   * @param {Order} order 
   * @param {string} status 
   * @returns 
   */
  function setOrderStatusHandler(order, status) {
    return async (e) => {
      setLoading(true);
      try {
        await updateOrderItemStatus(order, status);
        if (status === 'return-requested') {
          currentUser.callFunction('emailSeller', { orderId: `${order._id}`, origin: window.origin, sellerId: `${order.sellerId}` })
        }
      } catch (ex) {
        setLoading(false);
      }
      order.item.status = status;
      setOrder({ ...order });
      setLoading(false);
    }
  }

  return (<React.Fragment>
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>{order.item?.status}
            {order.item.status === 'sold' && " - waiting on Seller: " + order.item.seller[0]?.fullname}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Order placed</TableCell>
          <TableCell>{new Date(order.payment.create_time).toDateString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>{order.item?.title}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Price</TableCell>
          <TableCell>${order.item?.price.toFixed(2)}</TableCell>
        </TableRow>
        {order.item?.discountPrice ?
          <TableRow>
            <TableCell>Discount Price</TableCell>
            <TableCell>${order.item?.discountPrice.toFixed(2)}</TableCell>
          </TableRow>
        :""}
        <TableRow>
          <TableCell>Size</TableCell>
          <TableCell>{order.item?.size}</TableCell>
        </TableRow>
        {currentUser.customData.sellerStores?.includes(store?._id) &&
          <TableRow>
            <TableCell>Seller Action</TableCell>
            <TableCell>
              {order.item.status === 'sold' &&
                <>
                  <Button startIcon={<Check />} size="small" variant="outlined"
                    onClick={setOrderStatusHandler(order, 'packed')}>
                    Set Packed {loading && <CircularProgress />}
                  </Button>
                  {showButtons ?
                    <Button startIcon={<Check />} size="small"
                      variant="outlined" onClick={setOrderStatusHandler(order, 'returned')}>
                      Mark Returned {loading && <CircularProgress />}
                    </Button>
                    :
                    <IconButton onClick={() => setShowButtons(!showButtons)}>
                      <MoreVert />
                    </IconButton>
                  }
                </>
              }

              {order.item.status === 'packed' && <Button startIcon={<Cancel />} size="small"
                variant="outlined" color="warning" onClick={setOrderStatusHandler(order, 'sold')}>
                Undo Pack {loading && <CircularProgress />}
              </Button>}
              {order.item.status === 'return-requested' && <Button startIcon={<Done />} size="small"
                variant="outlined" color="info" onClick={setOrderStatusHandler(order, 'returned')}>
                Mark Returned {loading && <CircularProgress />}
              </Button>}
              {(order.item.status === 'returned' || order.item.status === 'testing') && <Button startIcon={<Cancel />} size="small"
                variant="outlined" color="warning" onClick={setOrderStatusHandler(order, 'sold')}>
                Undo Return {loading && <CircularProgress />}
              </Button>}
            </TableCell>
          </TableRow>
        }
        {currentUser.id === order.payerId &&
          <>
            <TableRow>
              <TableCell>Seller</TableCell>
              <TableCell>
                {order.item.seller[0]?.fullname}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Buyer Action</TableCell>
              <TableCell>
                {order.item.status === 'packed' && <Button startIcon={<KeyboardReturn />} size="small"
                  variant="outlined" onClick={setOrderStatusHandler(order, 'return-requested')}>
                  Request Return {loading && <CircularProgress />}
                </Button>}
                {order.item.status === 'return-requested' && <Button startIcon={<Cancel />} size="small"
                  variant="outlined" color="warning" onClick={setOrderStatusHandler(order, 'sold')}>
                  Cancel Pending Return {loading && <CircularProgress />}
                </Button>}
              </TableCell>
            </TableRow>
          </>
        }
        {currentUser.customData.sellerStores?.includes(store?._id) &&
          <TableRow>
            <TableCell>Consignor</TableCell>
            <TableCell>{order.item?.consignor}</TableCell>
          </TableRow>
        }
        <TableRow>
          <TableCell colSpan={2}>
            <ItemImage width={280} url={order.item?.imageUrl} alt={order.item?.title} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Sold</TableCell>
          <TableCell>{timeAgo(order.created_ts)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Source</TableCell>
          <TableCell>{order.data?.paymentSource}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Payer</TableCell>
          <TableCell>{order.payment?.payer?.email_address}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Paid</TableCell>
          <TableCell>${order.payment?.purchase_units[0]?.amount.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Payment ID</TableCell>
          <TableCell>{order.data?.paymentID}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Order ID</TableCell>
          <TableCell>{new BSON.ObjectID(order._id).toString()}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </React.Fragment>);
}

export default ViewOrder;