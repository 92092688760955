import React from "react";
import { Box, Button, Container, Divider, LinearProgress } from "@mui/material";
import { Link as RLink, useParams } from "react-router-dom";
import { useConsignors } from "../../../hooks/useConsignors";
import { useAxiosEndpoint } from "../../../hooks/useAxiosEndpoint";
import { filterOptions } from "../../../shared/helpers";
import { EditItemForm } from "./EditItemForm";
import { ArrowBack, Inventory } from "@mui/icons-material";
import { EditItemImageList } from "./EditItemImageList";
import { useRealmApp } from "../../RealmApp";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

export function EditItem({ store }) {
    const { currentUser } = useRealmApp();
    const { itemId } = useParams();
    const queryParams = { itemId };
    const { data, loading } = useAxiosEndpoint('getListing', { queryParams });
    /** @type {Listing} */
    const item = data?.documents[0];
    const { items: consignors } = useConsignors();
    const { categories } = store;
    const sizes = filterOptions(store.options, 'size');
    useDocumentTitle(`Edit Item: ${itemId} `);

    return (
        <Container disableGutters={false} maxWidth="sm">
            {loading ? <LinearProgress />
                : <React.Fragment>
                    <EditItemImageList item={item} currentUser={currentUser} />
                    <Box sx={{ m: 2 }}>
                        <EditItemForm
                            setOpen={""}
                            store={store}
                            item={item}
                            sizes={sizes}
                            categories={categories}
                            consignors={consignors} />
                    </Box>
                </React.Fragment>}
            <Divider sx={{ mt: 3 }} />
            <Button startIcon={<ArrowBack />} sx={{ py: 2 }} component={RLink} to={"/listings"}>Back to Listings</Button> /
            <Button startIcon={<Inventory />} component={RLink} to={`/items/${itemId}`}>View Item</Button>
        </Container>
    );
}