import React from "react";
import {
    ImageList,
    ImageListItemBar,
    ImageListItem,
  } from "@mui/material";
import { Link } from "react-router-dom";
import ItemImage from "../controls/ItemImage";

export function SingleImageList({items}) {
    return(
        <ImageList style={{marginTop:6}}
        sx={{
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(auto-fill,minmax(190px,1fr)) !important",
          gridAutoColumns: "minmax(190px, 1fr)"
        }}>
        {items.map((item) => (
          <ImageListItem key={item._id}>
            <Link to={"/items/"+ item._id}>
              <ItemImage url={item.imageUrl} height={190} alt={item.title} />
              <ImageListItemBar
                    title={item.title}
                    subtitle={<span>${item.price} size:{item.size} </span>}
                  />
            </Link>
          </ImageListItem>
        ))}
      </ImageList>
    )
}