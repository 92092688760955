import React from "react";
import { Button, Card, CardHeader, Divider, LinearProgress } from "@mui/material";
import { AddComment } from "@mui/icons-material";
import ConversationList from "./ConversationList";
import { useChats } from "./useChats";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

function ChatPage() {
    const { loading, chats } = useChats()
    useDocumentTitle('Chat');

    if (loading) return <LinearProgress />
    return (
        <Card sx={{ flexGrow: 1, p: 1, my: 2, mx: 'auto', maxWidth: 400 }}>
            <CardHeader
                sx={{ px: .4 }}
                action={
                    <Button component={Link} to={"/sellers"} variant="contained" endIcon={<AddComment />}>
                        New
                    </Button>
                }
                title={`Chats`}
            />
            <Divider sx={{ mb: 2 }} />
            {chats.length > 0 &&
                <ConversationList chats={chats} />
            }
        </Card>
    );
}

export default ChatPage;