import React from "react";

export const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = React.useState("");
    const timerRef = React.useRef();
    React.useEffect(() => {
      // @ts-ignore
      timerRef.current = setTimeout(() => setDebouncedValue(value), delay);
      return () => {
        clearTimeout(timerRef.current);
      };
    }, [value, delay]);
    return debouncedValue;
  };