import React from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useSalesTotals } from "../../../hooks/useSalesTotals";
import { Link } from "react-router-dom";

export function SellerReports() {
    const {loading, totals } = useSalesTotals();

    useDocumentTitle('SellerReports');

    function sortDateGroup(a, b) {
        return `${b.group.date}`.localeCompare(`${a.group.date}`)
    }

    // console.log({totals})

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ m: 1 }}>
                {!loading ?
                    <TableContainer sx={{m:3}}>
                        <Typography variant="h6">Seller Reports</Typography> 
                        <Button sx={{mb:1}} size="small" variant="outlined" component={Link} to={'/reports/seller/payouts'}>Consignor Payouts</Button>
                        <Table sx={{ maxWidth: 380, pageBreakInside:'auto' }} size="small" aria-label="seller orders">
                            <TableHead sx={{display:'table-header-group'}}>
                                <TableRow>
                                    <TableCell>Month</TableCell>
                                    <TableCell>Revenue</TableCell>
                                    <TableCell>Sales</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {totals.sort(sortDateGroup).map((i, idx) => (
                                    <TableRow hover tabIndex={-1} key={idx} sx={{'&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                         <Link to={`/reports/seller/month/${i.group.date}`}>{i.group.date}</Link>
                                        </TableCell>
                                        <TableCell>${i.sum ? i.sum.toFixed(2) : 0} </TableCell>
                                        <TableCell>{i.count}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : ""}
            </Paper>
        </Box>)
}
