import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation
} from "react-router-dom";
import { HomePage } from "./pages/Home/HomePage";
import { ListingsPage } from "./pages/Listing/ListingsPage";
import { RealmAppProvider, useRealmApp } from "../components/RealmApp";
import { ThemeProvider } from "../components/Theme";
import Config from "../shared/Config";
import "./App.css";
import AuthRedirect from "./pages/AuthRedirect";
import { ItemCardDetail } from "./pages/Item/ItemCardDetail";
import { Login } from "./pages/Login";
import { ProfilePage } from "./pages/Profile/ProfilePage";
import { RedirectPage } from "./pages/RedirectPage";
import { CategoryPage } from "./pages/CategoryPage";
import { useStoreSettings } from "../hooks/useStoreSettings";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { EmailConfirmPage } from "./pages/EmailConfirmPage";
import { OrdersPage } from "./pages/Orders/OrdersPage";
import { Sales } from "./pages/Orders/Sales";
import Export from "./pages/Export";
import { SellerPage } from "./pages/Seller";
import { EditItem } from "./pages/Listing/EditItem";
import ChatPage from "../modules/Chat/Chat";
import Conversation from "../modules/Chat/Conversation";
import { Register } from "./pages/Register";
import { CategoriesPage } from "./pages/CategoriesPage";
import { SellersPage } from "./pages/Sellers";
import { SellerReports } from "./pages/Reports/SellerReports";
// import { LinkAccount } from "./pages/LinkAccount";
import Debug from "debug";
import { Page } from "../modules/ContentPages/Page";
import { SalePage } from "./pages/SalePage";
import { PayoutReport } from "./pages/Reports/PayoutReport";
import { SellerMonthReport } from "./pages/Reports/SellerMonthReport";
import { ShopCategories } from "../modules/Admin/ShopCategories";
import Layout from "./Layout";
import { getEndpoint } from "../shared/getEndpoint";
import { ErrorPage } from "./pages/ErrorPage";
import { SearchPage } from "./pages/SearchPage";
const debug = Debug("App");

const { appId } = Config();

function RequireAuth({ children }) {
  const { currentUser } = useRealmApp();
  const location = useLocation();
  if (currentUser?.isLoggedIn === true) {
    return children
  } else {
    return <Navigate to={`/login?redirect=${location.pathname}`} replace />;
  }
}

export default function AppWithRealm() {
  return (
    <RealmAppProvider appId={appId}>
      {/* <PayPalScriptProvider deferLoading={false} options={initialOptions}> */}
      <ThemeProvider>
        <App />
      </ThemeProvider>
      {/* </PayPalScriptProvider> */}
    </RealmAppProvider>
  );
}

/**
 * App Component
 * @returns {JSX.Element}
 */
function App() {
  const { currentUser } = useRealmApp();
  const { store, loading } = useStoreSettings(); // use currentUser to force refresh when logged in
  const [showLogin, setShowLogin] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);

  React.useMemo(() => {
    debug('currentUser:', { currentUser });
  }, [currentUser])

  if (loading) {
    return;
  }

  async function itemLoader ({params}) {
    const queryParams = { itemId: params.itemId };
    return getEndpoint('getListing', queryParams);
  }

  async function pageLoader ({params}) {
    const queryParams = { slug: params.slug };
    const data = await getEndpoint('page', queryParams);
    if (!data[0]?.title) {
      throw Error("Not Found");
    }
    return data; 
  }

  const router = createBrowserRouter([
    {
      // id: 'root',
      path: '/',
      element: <Layout store={store} loading={loading} setShowLogin={setShowLogin} showLogin={showLogin} setShowSearch={setShowSearch} />,
      children: [
        { index: true, element: <HomePage store={store} showSearch={showSearch} setShowSearch={setShowSearch} /> },
        { 
          path: "items/:itemId",
          errorElement:<ErrorPage error={{message:"Item not found"}} />,
          loader: itemLoader, 
          element: <ItemCardDetail setShowLogin={setShowLogin} store={store} />
        },
        { path: "category/:category", element: <CategoryPage store={store} /> },
        { path: "sale", element: <SalePage store={store} /> },
        { 
          path: "page/:slug",
          errorElement:<ErrorPage error={{message:"Page2 not found"}} />,
          loader: pageLoader,
          element: <Page store={store} /> 
        },
        { 
          path: "/search",
          errorElement:<ErrorPage error={{message:"Not results found"}} />,
          //loader: searchLoader,
          element: <SearchPage store={store} /> 
        },
        { path: "/sellers", element: <RequireAuth><SellersPage store={store} /></RequireAuth> },
        { path: "/redirect", element: <RedirectPage /> },
        { path: "/login", element: <Login store={store} setShowSearch={setShowSearch} showSearch={showSearch} /> },
        { path: "/auth/callback", element: <AuthRedirect /> },
        { path: "/resetPassword", element: <ResetPasswordPage /> },
        { path: "/signup", element: <Register setShowLogin={setShowLogin} /> },
        // { path: "/link-account", element: <LinkAccount setShowLogin={setShowLogin} />},
        { path: "/confirm", element: <EmailConfirmPage /> },
        { path: "/categories", element: <CategoriesPage store={store} /> },
        { path: "/seller/:userId", element: <RequireAuth><SellerPage store={store} /></RequireAuth> },
        { path: "/listings", element: <RequireAuth><ListingsPage store={store} activeListings={true} currentUser={currentUser} /></RequireAuth> },
        { path: "/listings-inactive", element: <RequireAuth><ListingsPage store={store} currentUser={currentUser} /></RequireAuth> },
        { path: "/edititem/:itemId", element: <RequireAuth><EditItem store={store} /></RequireAuth> },
        { path: "/orders", element: <RequireAuth><OrdersPage currentUser={currentUser} /></RequireAuth> },
        { path: "/sales", element: <RequireAuth><Sales store={store} currentUser={currentUser} /></RequireAuth> },
        { path: "/reports/seller", element: <RequireAuth><SellerReports /></RequireAuth> },
        { path: "/reports/seller/month/:yearMonth", element: <RequireAuth><SellerMonthReport /></RequireAuth> },
        { path: "/reports/seller/payouts", element: <RequireAuth><PayoutReport /></RequireAuth> },
        { path: "/profile", element: <RequireAuth><ProfilePage store={store} currentUser={currentUser} /></RequireAuth> },
        { path: "/exports", element: <RequireAuth><Export /></RequireAuth> },
        { path: "/chat", element: <RequireAuth><ChatPage /></RequireAuth> },
        { path: "/chat/c/:conversationId", element: <RequireAuth><Conversation isDialog={undefined} chat={undefined} /></RequireAuth> },
        { path: "/admin/:shopId/categories", element: <RequireAuth><ShopCategories currentUser={currentUser} /></RequireAuth> },
        // { path: "*", element: <Root /> },
      ]
    }
  ]);

 return <RouterProvider router={router} />;
}
