import React from "react";
import { LoginDialog } from "../controls/LoginDialog";
import { HomePage } from "./Home/HomePage";
import { trackEvent } from "../../shared/helpers";

/**
 * Login Component
 * @param {object} Props
 * @param {Store} Props.store
 * @param {any} Props.setShowSearch
 * @param {any} Props.showSearch
 * @returns {JSX.Element}
 */
export function Login({ store, setShowSearch, showSearch }) {
  const [showLogin, setShowLogin] = React.useState(true);
  
  React.useEffect(() => {
    trackEvent({ action: 'showlogin' });
  }, []);

  return (
    <span>
      <LoginDialog
        store={store}
        open={showLogin}
        setShowLogin={setShowLogin}
      />
      <HomePage store={store} setShowSearch={setShowSearch} showSearch={showSearch} />
    </span>
  );
}
