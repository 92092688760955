import { Box, Card, CardMedia } from '@mui/material';
import React from 'react';
import { itemImageURL } from '../../../shared/helpers';

function Welcome({ store }) {

    return (
        <>
            {store.welcomeText?.length > 3 ?
                <Box component="section" sx={{ borderRadius: 1, p: 2, border: '1px dashed grey' }}>
                    {store.welcomeText}
                </Box> : ""}

            <Card sx={{ my: 1 }}>
                {store.welcomeImageUrl ?
                    <CardMedia
                        component="img"
                        style={{ margin:"auto", maxHeight: 380, maxWidth: 420 }}
                        image={itemImageURL(store.welcomeImageUrl, { variantOverride: '600pad' })}
                        title={"Welcome to " + store.name}
                    /> : ""}
            </Card>
        </>
    );
}

export default Welcome;