import React from "react";
import { Menu } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import { AppName } from "./AppName";
// import { useNavigate } from "react-router-dom";

export function AppTopBar({ currentUser, setDrawerOpen, setShowLogin, store }) {
  // const navigate = useNavigate();
  return (
    <AppBar position="static" color={store.theme?.split(',')[2] || 'transparent'}>
      <Toolbar>
        <AppName store={store} />
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={() => setDrawerOpen(true)}
            sx={{ mr: 2 }}
          >
            <Menu />
          </IconButton>
      </Toolbar>
    </AppBar>
  )
}