import React from "react";
import {
  Container,
  Typography,
  List,
  LinearProgress
} from "@mui/material";
import { useShowLoader } from "../../../hooks/util-hooks";
import { useMyOrders } from "../../../hooks/useMyOrders";
import { OrdersTable } from "./OrdersTable";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import UnReadChatBanner from "./UnReadChatBanner";

export function Sales({ store, currentUser }) {
  const { loading, orders } = useMyOrders({ partition: 'sellerId' });
  const showLoader = useShowLoader(loading, 200);
  useDocumentTitle('My Sales');

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div style={{ marginTop: 5 }}>
          <div>
            <UnReadChatBanner currentUser={currentUser} />
            <Typography style={{ marginLeft: 10 }} variant="h6">
              {`${orders.length} sale${orders.length === 1 ? "" : "s"
                }`}
            </Typography>
          </div>
          <List style={{ width: "100%" }}>
            <OrdersTable isSales={true} currentUser={currentUser} store={store} items={orders} />
          </List>
        </div>
      )}
    </Container>
  );
}
