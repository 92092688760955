import React from "react";
import Config from "../../shared/Config";
import { useCollection } from "../../hooks/useCollection";
const { dataSourceName } = Config();

export function useStores() {
//   const realmApp = useRealmApp();
  const [stores, setStores] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "stores",
  });

  React.useEffect(() => {
    collection.aggregate([]).then((res) => {
      console.log('useStores.js: fetch:mongo', { res })
      setStores(res);
      setLoading(false);
    });
  }, [collection]);

  const updateStore = async (store) => {
    console.log({ store })
    try {
      await collection.updateOne(
        { _id: store._id },
        { $set: store }
      );
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn('Error: tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending duplicate request');
      }
      console.error(err);
    }
  };

  return {
    loading,
    stores,
    updateStore
  };
}
