import React from "react";
import Debug from "debug";
import { Add, DeleteForeverRounded, Publish } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useConsignors } from "../../../hooks/useConsignors";
const debug = Debug("ConsignorEdit");

export function ConsignorEdit({ store }) {
  const [isEditMode, setEditMode] = React.useState(false);
  const [isFormDirty, setFormDirty] = React.useState(false);
  const { items: consignors, addConsignor, deleteConsignor } = useConsignors();

  const handleInputChange = (event) => {
    setFormDirty(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // @ts-ignore
    const { name, venmo } = Object.fromEntries(formData.entries());
    debug('addConsignor', { name, venmo });
    await addConsignor({ name, venmo });
    setEditMode(false);
  };

  return (
    <>
      <Typography>Consignors ({consignors?.length})</Typography>
      <Table sx={{ maxWidth: 380 }} size="small" aria-label="stores table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email | Venmo</TableCell>
            <TableCell>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consignors.map((c, idx) => (
            <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">{c.name}</TableCell>
              <TableCell>{c.venmo || '--'}</TableCell>
              <TableCell>
                <Button
                  size="small"
                  onClick={() => {
                    debug('deleteConsignor', { c });
                    deleteConsignor(c);
                  }}>
                  <DeleteForeverRounded />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="contained" size="small" color="primary"
        startIcon={<Add />}
        onClick={(e) => {
          setEditMode(true);
          setFormDirty(false);
        }}>Add Consignor</Button>
      {isEditMode &&
        <form className="consignor-edit-form" onSubmit={handleSave}>
          <TextField
            id="input-name"
            size="small"
            name="name"
            label="Name"
            onChange={handleInputChange}
            variant="outlined"
          />
          <TextField
            id="input-venmo"
            size="small"
            name="venmo"
            label="Email / Venmo"
            onChange={handleInputChange}
            variant="outlined"
          />
          <Button disabled={!isFormDirty}
            style={{ marginRight: 0 }} type="submit"
            variant="contained" color="primary">
            Save <Publish />
          </Button>
        </form>}
    </>
  )
}