import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { Facebook } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export function FBLoginButton() {
  const { origin, /* hostname */ } = window.location;
  const [loading, setLoading] = React.useState(false);
  const [searchParams] = useSearchParams();
  const enableFBogin = true; // TODO: get from storeSettings; enabledHostnames.includes(hostname);

  return (
    <Button
      sx={{ backgroundColor: '#1877F2' }}
      disabled={!enableFBogin}
      startIcon={<Facebook />}
      variant="contained"
      size="medium"
      
      onClick={(e) => {
        setLoading(true);
        // The redirect URI should be on the same domain as this app and
        // specified in the auth provider configuration.

        const fb = 'https://www.facebook.com/v18.0'
        const ds = 1234567
        const appid = '1249551169233739'
        const redirectUri = `${origin}/auth/callback`
        const options = '&display=popup&response_type=token&scope=email'
        if (searchParams.get('redirect')) {
          sessionStorage.setItem("redirect_pathname", searchParams.get('redirect'));
        } else {
          sessionStorage.setItem("redirect_pathname", window.location.pathname);
        }
        window.location.replace(`${fb}/dialog/oauth?client_id=${appid}&redirect_uri=${redirectUri}${options}&state={st=test123,ds=${ds}}`) 
        
      }}> Facebook Login
      {loading && <CircularProgress sx={{ml:1}} size={25} />}
      </Button>
  )
}